import React, { Component } from 'react'
import {NavLink} from 'react-router-dom'
import {Helmet} from 'react-helmet'

/* Components */
import HeaderSlideShow from './headerSlideShow/HeaderSlideShow.js'
import HeaderMobile from './headerMobile/HeaderMobile.js'


/* CSS */
import './header.css'

/* LOGO */
import logo from '../../img/SonneButton.png'


export default class Header extends Component {
    constructor() {
        super();
        this.toggleNav = this.toggleNav.bind(this);
        this.logoRef = React.createRef();
        
        /* fixed header var */
        this.topHeaderRef = React.createRef();
        this.headerSmall = false;
    }

    /* Add and Remove shrinkheader eventlistener */
    componentDidMount() {
        window.addEventListener('scroll', this.shrinkHeader);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.shrinkHeader);
    }

    /* TOGGLENAV */
    /* Func to toggle between open and closed mobile nav */
    toggleNav = () => {
        let navIcon = document.getElementById("header-top-navIcon");
        navIcon.classList.toggle("inactive-nav")
        navIcon.classList.toggle("active-nav")
        document.getElementById("header-top-navbar").classList.toggle("header-top-navbar-visible")
    }

    /* SHRINKHEADER */
    /* func to shrink the header when scrolled down */
    shrinkHeader = () => {
        if(!this.headerSmall){
            if (document.documentElement.scrollTop > 150){
                this.topHeaderRef.current.classList.add("header-top-shrinked")
                this.headerSmall = true;
            }
        } else {
            if (document.documentElement.scrollTop < 150){
                this.topHeaderRef.current.classList.remove("header-top-shrinked")
                this.headerSmall = false;
            }
        }
    }





    render() {
        return (
            <header className="header" id="header">
                <Helmet>
                    <meta name="description" content="Biologische Edelbrände vom Sonnleitner-Biobauernhof aus dem Herzen des Mostivertels: ehrliche, authentische, bodenständige handgefertigte Edelbrände aus Österreich." />
                    <title>Der Sonnleitner-Biobauernhof</title>
                </Helmet>

                {/* TOP HEADER */}
                <nav ref={this.topHeaderRef} className="header-top">
                    <div className="header-box">
                        <NavLink exact to="/ursprung" className="navLink sl-h2" alt="link to ursprung" activeClassName="activeLink" onClick={() => this.props.scrollToLogo()}>URSPRUNG</NavLink>
                        <NavLink to="/sortiment" className="navLink sl-h2" activeClassName="activeLink" onClick={() => this.props.scrollToLogo()}>UNSERE PRODUKTE</NavLink>
                        <NavLink exact to="/" onClick={() => this.props.scrollToLogo()}>
                            <h2 className="header-top-header sl-h1">Sonnleitner</h2>
                        </NavLink>
                        <NavLink exact to="/bestellen" className="navLink sl-h2" activeClassName="activeLink" onClick={() => this.props.scrollToLogo()}>IHRE BESTELLUNG</NavLink>
                        <NavLink exact to="/kontakt" className="navLink sl-h2" activeClassName="activeLink" onClick={() => this.props.scrollToLogo()}>KONTAKT</NavLink>
                        {/* MOBILE NAVBAR-BTN */}
                        <div className="header-top-navIcon inactive-nav" id="header-top-navIcon" onClick={() => this.toggleNav()}>
                            <span className="header-top-navIcon-line"></span>
                            <span className="header-top_navIcon-line"></span>
                            <span className="header-top_navIcon-line"></span>
                        </div> 
                    </div>
                </nav>

                {/* MOBILE NAVBAR */}
                <nav className="header-top-navbar" id="header-top-navbar">
                    <NavLink exact to="/ursprung" className="header-top-navLink" activeClassName="activeLink" onClick={() => {this.toggleNav(); this.props.scrollToLogo()}} >URSPRUNG</NavLink>
                    <NavLink exact to="/sortiment" className="header-top-navLink" activeClassName="activeLink" onClick={() => {this.toggleNav(); this.props.scrollToLogo()}} >UNSERE PRODUKTE</NavLink>
                    <NavLink exact to="/bestellen" className="header-top-navLink" activeClassName="activeLink" onClick={() => {this.toggleNav(); this.props.scrollToLogo()}} >IHRE BESTELLUNG</NavLink>
                    <NavLink exact to="/kontakt" className="header-top-navLink" activeClassName="activeLink" onClick={() => {this.toggleNav(); this.props.scrollToLogo()}} >KONTAKT</NavLink>
                </nav>

                {/* HEADER SLIDESHOW */}
                <div className="header-slideshow">
                    <HeaderSlideShow scrollToLogo={this.props.scrollToLogo} />
                </div>
                <div className="header-mobileImg">
                    <HeaderMobile scrollToLogo={this.props.scrollToLogo}/>
                </div>

                {/* BOTTOM HEADER */}
                <div className="header-bottom">
                    <div className="header-box">
                        
                        <h2 className="header-bottom-text sl-h2">
                            <NavLink to={"/sortiment"} onClick={() => this.props.scrollToLogo()}>ANSPRECHENDE DESTILLATION</NavLink>
                            </h2> 
                        <div className="header-bottom-logoBox">
                            <NavLink exact to="/">
                                <img ref={this.logoRef} id="header-bottom-logo" src={logo} className="header-bottom-logo" alt="der sonnleitner logo, gelbe Sonne, Schnapsbrennerei seit 1910" />
                            </NavLink>
                        </div>
                        <h2 className="header-bottom-text sl-h2">
                            <NavLink to={"/ursprung"} onClick={() => this.props.scrollToLogo()}>AUS FAMILIENTRADITION</NavLink>
                        </h2> 
                    </div>
                </div>
            </header>
        )
    }
}
