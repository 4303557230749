import React, { Component } from "react";
import { Redirect } from "react-router-dom";

/* CSS */
import "./headerSlider.css";

/* ICONS */
import sliderArrow from "../../../../img/arrowHeaderYellow.svg";

/* FIRST IMAGES */
/* load this images first while images for slider get loaded */
/* should prevent long loading times. Silderimages will be loaded after */
import startImg0 from "../../../../img/StartseiteSort.png"; /* normal version */
// import startImg0 from '../../../../img/winterWide.png'  /* winter version */
// import startImg0 from "../../../../img/spring2022.png"; /* Spring2022 version */
import urspImg1 from "../../../../img/Ursprung1.jpg";
import sortImg1 from "../../../../img/Sortiment1.jpg";
import bestImg1 from "../../../../img/Sortiment1.jpg";
import kontImg1 from "../../../../img/Kontakt.jpg";
import datenImg1 from "../../../../img/Datenschutzerklaerung.jpg";
import imprImg1 from "../../../../img/Impressum.jpg";
import agbImg1 from "../../../../img/Sortiment1.jpg";

/* SLIDER IMAGES */
/* These Images get loaded after the first Image was loaded, */
/* when all Images are loaded the Slider starts */
import startImg1 from "../../../../img/Startseite1.jpg";
import startImg2 from "../../../../img/Startseite2.jpg";
import startImg3 from "../../../../img/Startseite3.jpg";
import urspImg2 from "../../../../img/Ursprung2.jpg";
import urspImg3 from "../../../../img/Ursprung3.jpg";
import urspImg4 from "../../../../img/Ursprung4.jpg";
import sortImg2 from "../../../../img/Sortiment2.jpg";
import sortImg3 from "../../../../img/Sortiment3.jpg";
//import weihnachten from "../../../../img/weihnachten/weihnachten23.jpg";

/* CTA IMGS */
import ctaHeart from "../../../../img/sonnleitner_heart.png";

/* ARRAY: FIRST IMAGES */
let firstImgs = [
    startImg0,
    urspImg1,
    sortImg1,
    bestImg1,
    kontImg1,
    datenImg1,
    imprImg1,
    agbImg1,
];
// winter 23
// let firstImgs = [
//     [startImg0],
//     [startImg0],
//     [startImg0],
//     [startImg0],
//     [startImg0],
//     [startImg0],
//     [startImg0],
//     [startImg0],
// ];

// weihnachten 23
// let firstImgs = [
//     [weihnachten],
//     [weihnachten],
//     [weihnachten],
//     [weihnachten],
//     [weihnachten],
//     [weihnachten],
//     [weihnachten],
//     [weihnachten],
// ];

/* ARRAY: SLIDER IMAGES */
let sliderImgs = [
    [startImg0, startImg2, startImg3, startImg1],
    [urspImg1, urspImg2, urspImg3, urspImg4],
    [sortImg1, sortImg2, sortImg3],
    [bestImg1],
    [kontImg1],
    [datenImg1],
    [imprImg1],
    [agbImg1],
];
// let sliderImgs = [
//     [startImg0],
//     [startImg0],
//     [startImg0],
//     [startImg0],
//     [startImg0],
//     [startImg0],
//     [startImg0],
//     [startImg0],
// ];

// weihnachten 23
// let sliderImgs = [
//     [weihnachten],
//     [weihnachten],
//     [weihnachten],
//     [weihnachten],
//     [weihnachten],
//     [weihnachten],
//     [weihnachten],
//     [weihnachten],
// ];

/* ARRAY: Call to action objects */
/* Startseite CTA */
let startCta = [
    // Startseite Mai
    // {
    //     text: 'BLÜTENPRACHT <br> BRINGT NEUE TRACHT<br> DA UNS HELL <br> DIE SONNE LACHT.',
    //     btnText: 'FRISCH BESTELLEN',
    //     style: {
    //         left: '65%'
    //     },
    //     link: '/sortiment'
    // },

    // Startseite winter
    // {
    //     text: 'DRAUSSEN KALT? <br> DRINNEN WARM!',
    //     btnText: 'HIER AUFWÄRMEN',
    //     style: {
    //         left: '50%'
    //     },
    //     link: '/sortiment'
    // },
    // Startseite Spring 2022
    // {
    //     text: "EIN GUTER START IN DEN FRÜHLING?",
    //     btnText: "MIT UNSEREM </br> SORTIMENT",
    //     style: {
    //         left: "17%",
    //     },
    //     link: "/sortiment",
    // },

    // Startseite Sort
    // {
    //     text: "",
    //     btnText: "Entdecken Sie jetzt unser neues Sortiment!",
    //     style: {
    //         left: "calc(50% - 23.4rem)",
    //         top: "77%",
    //         display: "none",
    //     },
    //     link: "/sortiment",
    // },
    {
        text: "BIOLOGISCHE EDELBRÄNDE <br> DIREKT VOM BAUERN?",
        btnText: "JETZT BESTELLEN",
        style: {
            left: "55%",
        },
        link: "/sortiment",
    },
    {
        text: "BIOLOGISCHE EDELBRÄNDE <br> DIREKT VOM BAUERN?",
        btnText: "JETZT BESTELLEN",
        style: {
            left: "55%",
        },
        link: "/sortiment",
    },
    {
        text: "IM EINKLANG MIT TRADITION, NATUR <br> UND REGION",
        btnText: "ALLE PRODUKTE",
        style: {
            left: "65%",
        },
        link: "/sortiment",
    },
    {
        text: "QUALITÄT <br> AUS DEM HERZEN DES MOSTVIERTELS",
        btnText: "MEHR ERFAHREN",
        link: "/ursprung",
        style: {
            left: "25%",
            background: "red",
        },
        icon: {
            img: ctaHeart,
            width: "4rem",
            height: "4rem",
            top: "-2rem",
            right: "-2rem",
        },
    },
];

// let winterCta = [
//     // Startseite Weihnachten 23
//     {
//         text: "DER GABENTISCH IST GEDECKT",
//         btnText: "HIER G'SCHWIND BESTELLEN",
//         style: {
//             left: "40%",
//             maxWidth: "30rem",
//             top: "50%",
//         },
//         link: "/",
//     },
// ];

let sliderCtas = [
    startCta,
    startCta,
    startCta,
    startCta,
    startCta,
    startCta,
    startCta,
    startCta,
];

export class HeaderSlider extends Component {
    constructor() {
        super();
        this.state = {
            images: [], //images
            cta: [], //call to actions
            redir: false,
        };
        this.imgIndex = 0;
        this.sliderInterval = null;
        this.activeImageRef = React.createRef();
        this.prevImgBtnRef = React.createRef();
        this.nextImgBtnRef = React.createRef();
        this.ctaRef = React.createRef();
        this.ctaTextRef = React.createRef();
        this.ctaBtnRef = React.createRef();
        this.ctaLink = "/";
        this.ctaIconRef = React.createRef();
    }

    /* COMPONENTWILLMOUNT */
    /* when component gets mounted set this.state.sliderImgs to current Route */
    /* images */
    componentWillMount() {
        this.setState({
            images: sliderImgs[this.props.page],
            cta: sliderCtas[this.props.page],
        });
    }

    /* COMPONENTDIDMOUNT */
    /* preloads slider Images and starts slider after*/
    /* the images have loaded */
    componentDidMount() {
        /* set init cta */
        if (this.state.cta.length > 0) {
            this.ctaRef.current.style.display = "flex";

            //if cta has text
            if (this.state.cta[0].text === "") {
                this.ctaTextRef.current.style.display = "none";
            } else {
                this.ctaTextRef.current.style.display = "block";
            }

            this.ctaTextRef.current.innerHTML = this.state.cta[0].text;
            this.ctaBtnRef.current.innerHTML = this.state.cta[0].btnText;
            this.ctaLink = this.state.cta[0].link;
            this.ctaRef.current.style.left = this.state.cta[0].style.left;

            if (this.state.cta[0].style.top !== undefined) {
                this.ctaRef.current.style.top = this.state.cta[0].style.top;
            }

            if (this.state.cta[0].style.maxWidth !== undefined) {
                this.ctaTextRef.current.style.maxWidth =
                    this.state.cta[0].style.maxWidth;
            }

            //if cta has an icon
            if (typeof this.state.cta[0].icon !== "undefined") {
                this.ctaIconRef.current.src = this.state.cta[0].icon.img;
                this.ctaIconRef.current.style.display = "block";
                this.ctaIconRef.current.style.width =
                    this.state.cta[0].icon.width;
                this.ctaIconRef.current.style.height =
                    this.state.cta[0].icon.height;
                this.ctaIconRef.current.style.top = this.state.cta[0].icon.top;
                this.ctaIconRef.current.style.right =
                    this.state.cta[0].icon.right;
            }
        }

        if (this.state.images.length === 1) return; //if there are no images to slide stop
        for (let i = 0; i < this.state.images.length; i++) {
            const img = new Image();
            img.src = this.state.images[i];
        }

        this.slider(); // call slider with index 1, because img with index 0 was initially displayed
    }

    /* SLIDER */
    /* Slides through Images of Route, starts with image at pos of param index */
    /* in the sliderImgs array */
    slider = () => {
        clearInterval(this.sliderInterval);
        this.sliderInterval = setInterval(() => {
            this.imgIndex++;
            if (this.imgIndex >= this.state.images.length) this.imgIndex = 0;

            /* cta */
            if (this.state.cta.length > 0) {
                this.ctaRef.current.style.display = "none";
            }

            /* img */
            this.activeImageRef.current.src = this.state.images[this.imgIndex];

            /* cta */
            if (this.state.cta.length > 0) {
                //if cta has text
                if (this.state.cta[this.imgIndex].text === "") {
                    this.ctaTextRef.current.style.display = "none";
                } else {
                    this.ctaTextRef.current.style.display = "block";
                }

                this.ctaTextRef.current.innerHTML =
                    this.state.cta[this.imgIndex].text;
                this.ctaBtnRef.current.innerHTML =
                    this.state.cta[this.imgIndex].btnText;
                this.ctaLink = this.state.cta[this.imgIndex].link;
                this.ctaRef.current.style.left =
                    this.state.cta[this.imgIndex].style.left;

                if (this.state.cta[this.imgIndex].style.top !== undefined) {
                    this.ctaRef.current.style.top =
                        this.state.cta[this.imgIndex].style.top;
                } else {
                    this.ctaRef.current.style.top = "40%";
                }
                // small timeout before display flex
                setTimeout(() => {
                    this.ctaRef.current.style.display = "flex";
                }, 20);

                //if cta has an icon
                if (typeof this.state.cta[this.imgIndex].icon !== "undefined") {
                    this.ctaIconRef.current.src =
                        this.state.cta[this.imgIndex].icon.img;
                    this.ctaIconRef.current.style.display = "block";
                    this.ctaIconRef.current.style.width =
                        this.state.cta[this.imgIndex].icon.width;
                    this.ctaIconRef.current.style.height =
                        this.state.cta[this.imgIndex].icon.height;
                    this.ctaIconRef.current.style.top =
                        this.state.cta[this.imgIndex].icon.top;
                    this.ctaIconRef.current.style.right =
                        this.state.cta[this.imgIndex].icon.right;
                } else {
                    this.ctaIconRef.current.src = "";
                    this.ctaIconRef.current.style.display = "none";
                }
            }
        }, 10000);
    };

    /* COMPONENTWILLUNMOUNT */
    /* When Component gets umounted, clear old slider Interval */
    componentWillUnmount() {
        clearInterval(this.sliderInterval);
    }

    /* SWITCHIMAGE */
    /* param: Index of imgae in this.state.images to which background switches */
    /* for headerSliderbtns */
    switchimage = (index) => {
        if (this.state.images.length === 1) return;
        if (index >= this.state.images.length) index = 0;
        if (index < 0) index = this.state.images.length - 1;

        /* cta */
        if (this.state.cta.length > 0) {
            this.ctaRef.current.style.display = "none";
        }

        /* img */
        this.activeImageRef.current.src = this.state.images[index];

        /* cta */
        if (this.state.cta.length > 0) {
            //if cta has text
            if (this.state.cta[index].text === "") {
                this.ctaTextRef.current.style.display = "none";
            } else {
                this.ctaTextRef.current.style.display = "block";
            }

            this.ctaTextRef.current.innerHTML = this.state.cta[index].text;
            this.ctaBtnRef.current.innerHTML = this.state.cta[index].btnText;
            this.ctaLink = this.state.cta[index].link;
            this.ctaRef.current.style.left = this.state.cta[index].style.left;

            if (this.state.cta[index].style.top !== undefined) {
                this.ctaRef.current.style.top = this.state.cta[index].style.top;
            } else {
                this.ctaRef.current.style.top = "40%";
            }
            // small timeout before display flex
            setTimeout(() => {
                this.ctaRef.current.style.display = "flex";
            }, 20);

            //if cta has an icon
            if (typeof this.state.cta[index].icon !== "undefined") {
                this.ctaIconRef.current.src = this.state.cta[index].icon.img;
                this.ctaIconRef.current.style.display = "block";
                this.ctaIconRef.current.style.width =
                    this.state.cta[index].icon.width;
                this.ctaIconRef.current.style.height =
                    this.state.cta[index].icon.height;
                this.ctaIconRef.current.style.top =
                    this.state.cta[index].icon.top;
                this.ctaIconRef.current.style.right =
                    this.state.cta[index].icon.right;
            } else {
                this.ctaIconRef.current.src = "";
                this.ctaIconRef.current.style.display = "none";
            }
        }

        this.imgIndex = index;
        this.slider();
    };

    /* renderPrefBtn */
    /* checks if more than 1 header img was definded for this route & btn should be rendered */
    renderPrevBtn = () => {
        if (this.state.images.length > 1) {
            return (
                <div
                    ref={this.prevImgBtnRef}
                    className="headerSlider-btn headerSlider-btn-prev"
                >
                    <img
                        src={sliderArrow}
                        alt="arrow icon"
                        onClick={() => this.switchimage(this.imgIndex - 1)}
                    />
                </div>
            );
        }
    };

    /* renderNextBtn */
    /* checks if more than 1 header img was definded for this route & btn should be rendered */
    renderNextBtn = () => {
        if (this.state.images.length > 1) {
            return (
                <div
                    ref={this.nextImgBtnRef}
                    className="headerSlider-btn headerSlider-btn-next"
                >
                    <img
                        src={sliderArrow}
                        alt="arrow icon"
                        onClick={() => this.switchimage(this.imgIndex + 1)}
                    />
                </div>
            );
        }
    };

    /* CTA Redirect active */
    /* func to set redirect active after an CTA Button was clicked */
    CTAredirectActive = () => {
        this.setState({
            redir: true,
        });
    };

    /* CTA Redirect */
    /* redirects to current CTA Link */
    CTAredirect = () => {
        if (this.state.redir) {
            return <Redirect push to={this.ctaLink} />;
        }
    };

    render() {
        return (
            <div className="headerSlider">
                {this.renderPrevBtn()}
                <img
                    ref={this.activeImageRef}
                    src={firstImgs[this.props.page]}
                    alt=""
                />
                <div ref={this.ctaRef} className="headerSlider-CTA">
                    <div
                        disabled
                        ref={this.ctaTextRef}
                        className="headerSlider-CTA-text"
                    ></div>
                    <div
                        ref={this.ctaBtnRef}
                        className="headerSlider-CTA-btn"
                        onClick={() => {
                            this.props.scrollToLogo();
                            this.CTAredirectActive();
                        }}
                    ></div>
                    <div className="headerSlider-CTA-icon">
                        <img ref={this.ctaIconRef} src="" alt="" />
                    </div>
                </div>
                {this.renderNextBtn()}
                {/* redirect */}
                {this.CTAredirect()}
            </div>
        );
    }
}

export default HeaderSlider;
