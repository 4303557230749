import React, { Component } from "react";
import { NavLink } from "react-router-dom";

/* CSS */
import "../headerMobile.css";

let normalCta = {
    text: 'BIOLOGISCHE EDELBRÄNDE DIREKT \n VOM BAUERN?',
    link: "/sortiment",
    btnText: "JETZT ENTDECKEN!",
};

let weihnachten23Cta = {
    text: "DER GABENTISCH \n IST GEDECKT",
    link: "/",
    btnText: "JETZT BESTELLEN!",
};

let winter23Cta = {
    text: "DRAUSSEN KALT? \n DRINNEN WARM",
    link: "/sortiment",
    btnText: "HIER AUFWÄRMEN!",
};

let ctaObj = normalCta;

export class MobileCta extends Component {
    render() {
        return (
            <div className="headerMobile-CTA">
                <div className="headerMobile-CTA-text">
                    {ctaObj.text}
                    {/* normal version */}
                    {/* DRAUSSEN KALT? <br/> DRINNEN WARM!  */}{" "}
                    {/* winter version */}
                </div>
                <NavLink
                    to={ctaObj.link}
                    className="headerMobile-CTA-btn"
                    onClick={() => this.props.scrollToLogo()}
                >
                    {ctaObj.btnText} {/* normal version */}
                    {/* HIER AUFWÄRMEN! */} {/* winter version */}
                </NavLink>
            </div>
        );
    }
}

export default MobileCta;
