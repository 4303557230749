import React, { Component } from 'react'
import {Helmet} from 'react-helmet'

/* CSS */
import './productRoute.css'



export class ProductRoute extends Component {
    constructor(){
        super();
        this.bigImgRef = React.createRef();
        this.sizeRef = React.createRef();
        this.amountRef = React.createRef();
    }

    /* CHANGE BIG IMAGE */
    /* Swapps the big image with the clicked one  */
    changeBigImg = (imageSrc) => {
        this.bigImgRef.current.src = imageSrc;
    }

    /* ORDER */
    /* gathers version and amount data & adds Product to cart */
    order = () => {
        /* create cartProduct */
        let cartProduct = {
            name: this.props.product.name,
            version: null,
            price: null
        }

        /* get size */
        let size = this.sizeRef.current.value;
        /* check if version is valid and get price */
        for(let i = 0; i < this.props.product.versions.length; i++){
            if(this.props.product.versions[i].size === size){
                cartProduct.version = this.props.product.versions[i];
            }
        }

        /* error if size is invalid and no size was added => dont add & alert user */
        if (cartProduct.version == null) {
            console.error("error: could not get product version (null)")
            alert("There was an error while adding your product, please try it later again")
        }

        /* get amount */
        let amount = parseInt(this.amountRef.current.value);
        this.props.addFromSort(cartProduct, amount);
    }

    render() {

        /* If a Bottle is 0,5L the small imgs should position on the left  */
        /* adds a class to the smallImgs Box */
        let smallImgsPos = "";

        console.log(this.props.product.versions[0].size)
        if(this.props.product.versions[0].size === '0.5L') {
            smallImgsPos = "productRoute-imgBox-smallImgs-posLeft";
        }

        return (
            <div className="productRoute">
                <Helmet>
                    <title>Der Sonnleitner-Biobauernhof {this.props.product.name}</title>
                    <meta name="description" content={this.props.product.text}/>
                </Helmet>
                <h1 className="productRoute-mobileHeader sl-h2">{this.props.product.name}</h1>
                {/* IMAGE */}
                <div className="productRoute-imgBox">
                    
                    <img ref={this.bigImgRef}
                         src={this.props.product.img[0]}
                         alt={this.props.product.name + " Flasche"}
                         className="productRoute-imgBox-bigImg"
                         />
                    <div className={"productRoute-imgBox-smallImgs " + smallImgsPos}>
                        {
                            

                            this.props.product.img.map(image => (
                                
                                <img 
                                    src={image}
                                    alt={this.props.product.name + " Flaschenversion"} 
                                    onClick={() => {this.changeBigImg(image)}}    
                                />
                            ))
                        }
                    </div>
                </div>

                {/* TEXT & ORDER*/}
                <div className="productRoute-textBox">
                    {/* TEXT */}
                    <h1 className="productRoute-textBox-header sl-h2">{this.props.product.name}</h1>
                    <p className="productRoute-textBox-text sl-Text">{this.props.product.text}</p>
                    <div className="productRoute-textBox-versionBox sl_Text">
                        {
                            this.props.product.versions.map(version => (
                                <p>{version.size + " - " + version.price}</p>
                            ))
                        }
                    </div>
                    <div className="productRoute-textBox-mwst sl-Text">Alle Preise inkl. 13% Mehrwertsteuer, zuzüglich Versandkosten.</div>
                    {/* ORDER */}
                    <div className="productRoute-textBox-select">
                        <div className="productRoute-textBox-select-size productRoute-textBox-select-box sl-Text">
                            Größe
                            <select name="size" ref={this.sizeRef}>
                                {
                                    this.props.product.versions.map(version => (
                                        <option value={version.size}>{version.size}</option>
                                    ))
                                }
                            </select>
                        </div>
                        
                        <div className="productRoute-textBox-select-amount productRoute-textBox-select-box sl-Text">
                            Menge
                            <input type="number" min="1" defaultValue="1" max="999" ref={this.amountRef}/>
                        </div>
                        <div className="productRoute-textBox-select-orderBtn sl-Text" onClick={() => {this.order(); this.props.activateBestBtn()}}>
                            Bestellen
                        </div>
                    </div>
                </div>
                
            </div>
        )
    }
}

export default ProductRoute