import React, { Component } from 'react'
import {NavLink} from 'react-router-dom'
import {Helmet} from 'react-helmet'

import './impressum.css'

export default class Impressum extends Component {
    render() {
        return (
            <div className="impressum sl-Box">
                <Helmet>
                    <title>Der Sonnleitner-Biobauernhof Impressum</title>
                    <meta name="description" content="Das Impressum und Informationen zu Medieninhalter und Herausgeber unserer Sonnenseite www.dersonnleitner.at" />
                </Helmet>
                <h2 className="sl-h3">Impressum</h2>
                <p>Informationspflicht laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63 Gewerbeordnung und Offenlegungspflicht laut §25 Mediengesetz.</p>
                <h3 className="sl-h2">Medieninhaber/Herausgeber</h3>
                <p>
                    Ludwig Sonnleitner <br/>
                    Rotte Doppel 11<br/>
                    A-3340 Sonntagberg<br/>
                    Österreich <br/>
                    Tel.: +436767822612 <br/>
                    E-Mail: <a href="mailto:kontakt@dersonnleitner.at">kontakt@dersonnleitner.at</a>
                </p>

                <h3 className="sl-h2">EU-Streitschlichtung</h3>
                <p>
                    Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie über die Online-Streitbeilegungsplattform (OS-Plattform) informieren.
                    Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der Europäischen Kommission unter <a href="http://ec.europa.eu/odr?tid=221133223" target="_blank" rel="noopener noreferrer">http://ec.europa.eu/odr?tid=221133223</a> zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in unserem Impressum.
                    Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
                </p>

                <h3 className="sl-h2">Haftung für die Inhalte dieser Website</h3>
                <p>
                    Wir entwickeln die Inhalte dieser Webseite ständig weiter und bemühen uns korrekte und aktuelle Informationen bereitzustellen. Leider können wir keine Haftung für die Korrektheit aller Inhalte auf dieser Webseite übernehmen, speziell für jene die seitens Dritter bereitgestellt wurden.
                    Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitten wir Sie uns umgehend zu kontaktieren, Sie finden die Kontaktdaten im Impressum.
                </p>

                <h3 className="sl-h2">Haftung für Links auf dieser Website</h3>
                <p>
                    Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt wir nicht verantwortlich sind. Haftung für verlinkte Websites besteht laut § 17 ECG für uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten hatten und haben, uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links sofort entfernen würden, wenn uns Rechtswidrigkeiten bekannt werden.
                    Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitten wir Sie uns zu kontaktieren, Sie finden die Kontaktdaten im Impressum.
                </p>

                <h3 className="sl-h2">Urheberrechtshinweis</h3>
                <p>Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht. Falls notwendig, werden wir die unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich verfolgen.</p>

                <h3 className="sl-h2">Bildernachweis</h3>
                <p>
                    Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich geschützt. Die Bilderrechte liegen bei den folgenden Fotografen und Unternehmen: <br/>
                    Fotografin: Martina Fließer <br/>
                    Fotograf: Raffael Hörlesberger <br/>
                    Fam. Sonnleitner
                </p>

                <h3 className="sl-h2">Website</h3>
                <p>
                    Diese Website wurde erstellt von:   <br/>
                    Daniel Baumgartinger
                </p>

                <h3 className="sl-h2">Datenschutz</h3>
                <p>
                    Der Schutz und die sichere Verarbeitung Ihrer Daten ist uns ein besonderes Anliegen. Bitte beachten Sie dazu unsere <NavLink exact to="/datenschutz">Datenschutzerklärung</NavLink>. <br/>
                    <div className="impressum-generator">
                        Quelle: erstellt mit dem <a href="https://www.adsimple.at/impressum-generator/" target="_blank" rel="noopener noreferrer">Impressum Generator von adsimple.</a>
                         
                    </div>
                    
                </p>
            </div>
        )
    }
}
