import React, { Component } from 'react'
import {Route} from 'react-router-dom'

/* COMPONENTS */
import HeaderSlider from './headerSlider/HeaderSlider'

/* CSS */
import './headerSlideShow.css'


/* PAGE NUMBER ENUM */
let pageNum = {
    STARTSEITE: 0,
    URSPRUNG: 1,
    SORTIMENT: 2,
    BESTELLEN: 3,
    KONTAKT: 4,
    IMPRESSUM: 5,
    DATENSCHUTZ: 6
}
Object.freeze(pageNum)

export class HeaderSlideShow extends Component {


    render() {
        return (
            <div className="headerSlideshow">
                <Route exact path={"/"}>
                    <HeaderSlider page={pageNum.STARTSEITE} scrollToLogo={this.props.scrollToLogo}/>
                </Route>
                <Route exact path={"/ursprung"}>
                    <HeaderSlider page={pageNum.URSPRUNG} scrollToLogo={this.props.scrollToLogo}/>
                </Route>
                <Route path={"/sortiment"}>
                    <HeaderSlider page={pageNum.SORTIMENT} scrollToLogo={this.props.scrollToLogo}/>
                </Route>
                <Route exact path={"/bestellen"}>
                    <HeaderSlider page={pageNum.BESTELLEN} scrollToLogo={this.props.scrollToLogo}/>
                </Route>
                <Route exact path={"/kontakt"}>
                    <HeaderSlider page={pageNum.KONTAKT} scrollToLogo={this.props.scrollToLogo}/>
                </Route>
                <Route exact path={"/datenschutz"}>
                    <HeaderSlider page={pageNum.DATENSCHUTZ} scrollToLogo={this.props.scrollToLogo}/>
                </Route>
                <Route exact path={"/impressum"}>
                    <HeaderSlider page={pageNum.IMPRESSUM} scrollToLogo={this.props.scrollToLogo}/>
                </Route>
                <Route exact path={"/agb"}>
                    <HeaderSlider page={pageNum.IMPRESSUM} scrollToLogo={this.props.scrollToLogo}/>
                </Route>
                <Route exact path={"/jugendschutz"}>
                    <HeaderSlider page={pageNum.IMPRESSUM} scrollToLogo={this.props.scrollToLogo}/>
                </Route>
                <Route exact path={"/success"}>
                    <HeaderSlider page={pageNum.IMPRESSUM} scrollToLogo={this.props.scrollToLogo}/>
                </Route>
            </div>
        )
    }
}

export default HeaderSlideShow
