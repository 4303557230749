import React, { Component } from 'react'
import {Helmet} from 'react-helmet'

import './agb.css'

export default class agb extends Component {
    render() {
        return (
            <div className="agb sl-Box">
                <Helmet>
                    <title>Der Sonnleitner-Biobauernhof AGB</title>
                    <meta name="decription" content="Die Allgemeinen Geschäftsbedingungen des Sonnleitner-Biobauernhofs."/>
                </Helmet>
                <h2 className="agb_header sl-h2">Allgemeine Geschäftsbedingungen (AGB)</h2>

                <h3 className="agb_header sl-h3">1. Verwender</h3>
                <div className="agb_text sg_Text">
                    Als Verwender dieser AGB gilt:  <br/><br/>
                    Der Sonnleitner-Biohof <br/>
                    Ludwig Sonnleitner <br/>
                    Rotte Doppel 11 <br/>
                    A-3340 Sonntagberg <br/>
                    Email: <a href="mailTo:kontakt@dersonnleitner.at">kontakt@dersonnleitner.at</a> <br/>
                    Telefon: +43676822612
                </div> 

                <h3 className="agb_header sl-h3">2. Geltungsbereich</h3>
                <div className="agb_text sg_Text">
                    Diese Allgemeinen Geschäftsbedingungen gelten für alle Rechtsgeschäfte zwischen dem Verwender und einem Verbraucher.
                </div>

                <h3 className="agb_header sl-h3">3. Vertragsschluss und Speicherung des Vertragstextes</h3>
                <div className="agb_text sg_Text">
                    Die Bestimmungen dieser AGB gelten für Bestellungen, welche Verbraucher über die Webseite www.dersonnleitner.at oder den Ab-Hof-Verkauf abschließen. Der Vertrag kommt mit dem Verwender (siehe 1) zustande. Verbraucher (in weiterer Folge „Kunde“ genannt“): Bei Rechtsgeschäften mit Verbrauchern im Sinne des österreichischen Konsumentenschutzgesetzes gelten diese Allgemeinen Geschäfts- und Lieferbedingungen insoweit, das sie nicht zwingend anzuwendenden Bestimmungen des Konsumentenschutzgesetzes widersprechen. <br/> <br/>
                    Die Vorstellung und Beschreibung der Waren auf der Internetseite www.dersonnleitner.at stellt kein Vertragsangebot dar. Mit der Bestellung einer Ware am Ende des Bestellvorgangs gibt ein Kunde ein verbindliches Angebot auf einen Kaufvertragsabschluss ab. Der Kaufvertrag kommt erst zustande, sobald wir die bestellte Ware ausliefern bzw. übergeben. <br/> <br/>
                    Wir behalten uns das Recht vor, das Leistungsangebot unser Web- und Verkaufsangebot inhaltlich jederzeit zu verändern. Die Preise für die angebotenen Lieferungen und Leistungen enthalten nicht Kosten, die von Dritten verrechnet werden. <br/> Sollten nicht alle bestellten Waren sofort lieferbar sein, werden die lagernde Waren sofort versandt und alle weiteren werden sofort bei Verfügbarkeit nachgeliefert. 
                </div>

                <h3 className="agb_header sl-h3">4. Zahlung</h3>
                <div className="agb_text sg_Text">
                    Alle angegebenen Preise sind in EURO angegeben. Die gesetzliche Mehrwertsteuer sowie weitere Preisbestandteile sind in den angegebenen Preisen inbegriffen. Versandkosten sind nicht im angezeigten Preis enthalten und können ggf. zusätzlich anfallen. <br/>
                    Kunden stehen folgende Zahlungsoptionen zur Verfügung: <br/> <br/>
                    Vorkasse durch Überweisung  <br/>
                    Barzahlung bei Abholung bzw. Übergabe <br/>
                </div>

                <h3 className="agb_header sl-h3">5. Lieferung, Lieferungsbeschränkungen</h3>
                <div className="agb_text sg_Text">
                    Der Versand erfolgt durch die österreichische Post. Ein Versand unserer Edelbrände ist nur an eine Adresse in Österreich möglich. Die Lieferung erfolgt – sofern die Beschreibung eines gewählten Produkts nicht explizit Abweichendes festlegt – umgehend nach Zahlungseingang, spätestens aber innerhalb von 7 Werktagen nach Zahlungseingang. Diese Frist beginnt im Falle der Zahlung via Überweisung am Werktag nach Erteilung des Zahlungsauftrages zu laufen. <br/>
                    Sollte es ausnahmsweise einmal zu einer Verzögerung der Lieferung einer Bestellung kommen, so nehmen wir mit dem Kunden umgehend Kontakt auf und vereinbaren mit ihm einen späteren Liefertermin. Sollte dieser damit nicht einverstanden sein, ist er berechtigt vom Vertrag zurückzutreten. <br/>
                </div>

                <h3 className="agb_header sl-h3">6. Versandkosten</h3>
                <div className="agb_text sg_Text">
                    Die Höhe der Versandkosten ist abhängig von der Größe Ihrer Bestellung: <br/>
                    <ul>
                        <li>6€ Versandkosten bei einem Gesamtbestellwert bis zu 20€</li>
                        <li>11€ Versandkosten bei einem Gesamtbestellwert von 21 bis 150€</li>
                        <li>Versandkostenfrei bei einem Gesamtbestellwert ab 151€</li>
                    </ul>
                </div>

                <h3 className="agb_header sl-h3">7. Gefahrenübergang</h3>
                <div className="agb_text sg_Text">
                    Das Risiko einer zufälligen Verschlechterung oder einem zufälligen Verlust der Ware liegt bis zur Übergabe der Ware beim Verwender und geht mit der Übergabe auf den Verbraucher über.
                </div>

                <h3 className="agb_header sl-h3">8. Eigentumsvorbehalt</h3>
                <div className="agb_text sg_Text">
                    Bis zum vollständigen Empfang des Kaufpreises behält sich der Verwender das Eigentum an der Ware vor.
                </div>

                <h3 className="agb_header sl-h3">9. Rücktrittsrecht des Kunden (Verbrauchers)</h3>
                <div className="agb_text sg_Text">
                    Sie können Ihre Vertragserklärung innerhalb von zwei Wochen ohne Angabe von Gründen in Textform (z. B. Brief, Fax, E-Mail) oder durch rechtzeitige Rücksendung der Sache widerrufen. Die Frist beginnt frühestens mit Erhalt dieser Belehrung. Zur Wahrung der Widerrufsfrist genügt die rechtzeitige Absendung des Widerrufs oder der Sache. Der Widerruf muss schriftlich erfolgen und ist zu richten an: <br/> <br/>
                    Ludwig Sonnleitner <br/>
                    Rotte Doppel 11<br/>
                    A-3340 Sonntagberg <br/>
                    Email: <a href="mailto:kontakt@dersonnleitner.at">kontakt@dersonnleitner.at</a> <br/> 
                </div>

                <h3 className="agb_header sl-h3">10. Widerrufsrecht</h3>
                <div className="agb_text sg_Text">
                    Sie haben das Recht binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. <br/>
                    Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag, an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die Waren in Besitz genommen haben bzw. hat; <br/>
                    Um Ihr Widerrufsrecht auszuüben, müssen Sie uns <br/> <br/>
                    Ludwig Sonnleitner <br/>
                    Rotte Doppel 11<br/>
                    A-3340 Sonntagberg <br/>
                    Email: <a href="mailto:kontakt@dersonnleitner.at">kontakt@dersonnleitner.at</a> <br/> 
                    mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren.

                </div>

                <h3 className="agb_header sl-h3">Folgen des Widerrufs</h3>
                <div className="agb_text sg_Text">
                    Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben (inkl. Standard-Versand- bzw. Lieferkosten), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde schriftlich ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung zusätzliche Entgelte berechnet. <br/> <br/>
                    Wir können die Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass Sie die Waren zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist. <br/>
                    Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses Vertrags unterrichten an <br/> <br/>
                    Ludwig Sonnleitner <br/>
                    Rotte Doppel 11<br/>
                    A-3340 Sonntagberg <br/>
                    Email: <a href="mailto:kontakt@dersonnleitner.at">kontakt@dersonnleitner.at</a> <br/> 
                    zurückzusenden oder zu übergeben. <br/> <br/>
                    Die Frist ist gewahrt, wenn Sie nach Eingang der schriftlichen Erklärung die Waren vor Ablauf der Frist von vierzehn Tagen absenden. <br/>
                    Sie tragen die unmittelbaren Kosten der Rücksendung der Waren oder etwaige Kosten, die uns durch eine Rückgabe entstehen wie z.B. Transportkosten, Warenlagerung. <br/>
                    Sie müssen im Falle eines Widerrufs für einen etwaigen Wertverlust der Waren aufkommen.
                </div>




            </div>
        )
    }
}
