import React, { Component } from 'react'
import {NavLink} from 'react-router-dom'
import axios from 'axios'
import {Helmet} from 'react-helmet'


/* CSS */
import './kontakt.css'

/* ICONS */
import facebook from '../../../../img/Facebook.png'
import instagram from '../../../../img/Instagram.png'
import close from '../../../../img/close.svg'

export default class Kontakt extends Component {
    constructor() {
        super();
        this.nameRef = React.createRef();
        this.emailRef = React.createRef();
        this.textRef = React.createRef();
        this.submitRef = React.createRef();
        this.messageSendRef = React.createRef();
    }

    /* SEND MESSAGE */
    /* check and collect Form data */
    /* gives error if data is not valid & calls mail func is data is correct */
    sendMessage = () => {
        let error = false;
        let errorElem = document.createElement("p")
        let errorMsg = "Bitte füllen sie folgende Felder aus: "

        /* delete old error if existing */
        let errorOld = document.getElementById("contactForm-submitError")
        if (errorOld != null) errorOld.remove();

        //check name
        if (this.nameRef.current.value === '') {
            this.nameRef.current.classList.add("contactForm-emptyAlert")
            errorMsg += ' Ihr Name ,'
            error = true;
        }

        //check email
        if (this.emailRef.current.value === '') {
            this.emailRef.current.classList.add("contactForm-emptyAlert")
            errorMsg += ' Ihre Email ,'
            error = true;
        }

        //check text
        if (this.textRef.current.value === '') {
            this.textRef.current.classList.add("contactForm-emptyAlert")
            errorMsg += ' Ihre Nachricht ,'
            error = true;
        }


        if (error) {
            errorElem.id = "contactForm-submitError"
            errorElem.classList.add("contactForm-submit-error")
            errorMsg = errorMsg.slice(0, -1) //delete last ","
            errorElem.innerHTML = errorMsg;
            this.submitRef.current.appendChild(errorElem)
        } else {
            let data = {
                reciver: "kontakt",
                name: this.nameRef.current.value,
                email: this.emailRef.current.value,
                text: this.textRef.current.value
            }

            this.email(data);
            
        }

    }

    /* Email */
    /* sets redirect if successful */
    email = async (data) => {
        await axios.post('https://www.dersonnleitner.at/mail.php', data, {
            headers: {
                'content-type': 'text/json'
            }
        }).then((resp) => {
            if (resp.data === 1) {
                this.messageSendRef.current.classList.add("contact-messageSend-active")
            } else {
                console.log(resp)
                /* delete old error if existing */
                let errorOld = document.getElementById("contactForm-submitError")
                if (errorOld != null) errorOld.remove();

                let errorElem = document.createElement("p")
                errorElem.classList.add("contactForm-submit-error")
                errorElem.innerHTML = "Wir konnten leider keine Email an die von Ihnen angegebene Email-Adresse senden. Bitte überprüfen Sie diese und versuchen es erneut."
                this.submitRef.current.appendChild(errorElem)
            }
        })
    }

    closeMessageSend = () => {
        this.messageSendRef.current.classList.remove("contact-messageSend-active")
    }
    

    render() {
        return (
            <div className="contact sl-Box">
                <Helmet>
                    <title>Der Sonnleitner-Biobauernhof Kontakt</title>
                    <meta name="description" content="Sie sind an unseren Produkten interessiert oder an einer Kooperation mit uns? Kontaktieren Sie uns und folgen Sie uns auf Instagram und Facebook!" />
                </Helmet>

                <div className="contact-elem1">
                    {/* MESSAGE SEND ALERT */}
                    <div ref={this.messageSendRef} className="contact-messageSend">
                        <div className="contact-messageSendBox">
                            <div className="contact-messageSend-header">Vielen Dank für Ihre Nachricht!</div>
                            <div className="contact-messageSend-text sl-Text">Wir haben Ihre Nachricht erhalten und werden uns bei Ihnen in kürze Melden.</div>
                            <div className="contact-messageSend-close">
                                <img src={close} alt="close icon" onClick={() => this.closeMessageSend()}/>
                            </div>
                        </div>
                    </div>


                    {/* DETAILS */}
                    <div className="contact-details sl-Text">
                        <h2 className="contact-details-header sl-h2">Kontakt</h2>
                        <div className="contact-details-addr">
                            Fam. Sonnleitner <br />
                            Rotte Doppel 11<br />
                            A-3340 Sonntagberg<br /><br />
                        </div>
                        <div className="contact-details-contact">
                            Email: <a href="mailto:kontakt@dersonnleitner.at?subject=Der Sonnleitner-Kontaktformular">kontakt@dersonnleitner.at</a> <br />
                            Telefon: +436767822612<br /><br />
                        </div>
                        <div className="contact_details_bank">
                            Bankverbindung: <br />
                            Ludwig Sonnleitner <br />
                            Bank: Raiffeisen Bank   <br />
                            IBAN: AT08 3290 6000 0009 5125  <br />
                            BIC: RLNWATWWWHY<br /><br />
                        </div>
                    </div>

                    {/* KONTAKT FORM */}
                    <div className="contact-form">
                        <div className="contact-form-box">
                            <div className="contact-form-header">
                                Ihre Nachricht
                            <div className="contact-form-header-line sl-Line sl-Line-norm"></div>
                            </div>
                            {/* form */}
                            <form className="contactForm" action="">
                                {/* NAME */}
                                <div className="contactForm-inputBox contactForm-topInputBox">
                                    <label htmlFor="formName">Ihr Name</label>
                                    <input ref={this.nameRef} className="contactForm-inputBox-input" type="text" placeholder="Geben Sie hier Ihren Namen ein" />
                                </div>

                                {/* EMAIL */}
                                <div className="contactForm-inputBox contactForm-topInputBox">
                                    <label htmlFor="formEmail">Ihre Email-Adresse</label>
                                    <input ref={this.emailRef} className="contactFomr-inputBox-input" type="text" placeholder="Geben Sie hier Ihre Email-Adresse ein" />
                                </div>

                                {/* TEXT AREA */}
                                <div className="contactForm-inputBox contactForm-textInput">
                                    <label htmlFor="formText">Ihre Nachricht</label>
                                    <textarea ref={this.textRef} id="formText" type="text" placeholder="Ihre Nachricht..." />
                                </div>

                                {/* SUBMIT */}
                                <div ref={this.submitRef} className="contactForm-submit">
                                    <input id="contactForm-submit-btn" type="Button" value="Senden" onClick={() => this.sendMessage()}/>
                                    <p className="contactForm-submit-text">
                                        Wir verarbeiten Ihre Daten zum Zwecke der Bearbeitung Ihrer Bestellung <br />
                                    Für weitere Informationen lesen Sie bitte unsere <NavLink exact to="/datenschutz">Datenschutzerklärung</NavLink>.
                                </p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                
                {/* ELEMENT 2 */}
                <div className="contact-elem2 sl-Text">
                    <div className="contact-socialMedia">
                        {/* SOCIAL MEDIA TEXT */}
                        <div className="contact-socialMedia-text">
                            Folgen Sie uns auf Facebook und Instagram!
                        </div>
                        {/* SOCIAL MEDIA ICONS */}
                        <div className="contact-socialMedia-links">
                            <a href="https://www.facebook.com/Der-Sonnleitner-109103574281810/" target="_blank" rel="noopener noreferrer">
                                <img src={facebook} alt="" />
                            </a>
                            <a href="https://www.instagram.com/der_sonnleitner/" target="_blank" rel="noopener noreferrer">
                                <img src={instagram} alt="" />
                            </a>
                        </div>
                    </div>
                </div>

                {/* ELEMENT 3 */}
                <iframe className="contact-map" title="sonnleitnerMaps" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5338.981704836322!2d14.818445!3d48.004226!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4772373603cb057d%3A0x69f8add1a394b897!2sDer%20Sonnleitner-Biobauernhof!5e0!3m2!1sde!2sat!4v1612155782955!5m2!1sde!2sat" width="600" height="450" frameBorder={""} allowFullScreen="" aria-hidden="false" tabIndex={"0"}></iframe>


                {/* ELEMENT 4 & 5 */}
                <div className="contact-elem4">
                    <h2 className="contact-elem4-header sl-h2">Marketing & Kooperationen</h2>
                    <div className="contact-elem4-line sl-Line sl-Line-norm"></div>
                    <div className="contact-elem4-text sl-Text">
                        Unsere Familie lebt ihre Leidenschaft seit über 100 Jahren im Einklang mit Tradition, Region und Natur. Nachhaltig der Natur und den Menschen verpflichtet sind wir dabei auch immer auf der Suche nach innovativen Ideen und Gleichgesinnten. <br />
                        Sie teilen diese Werte und möchten mit uns kooperieren oder möchten uns als Partner gewinnen? <br />
                        Wenden Sie sich bitte an <a href='mailto:kontakt@dersonnleinter.at?subject=Der Sonnleitner - Marketing und Kooperationen'>kontakt@dersonnleitner.at</a> <br />
                        Wir freuen uns auf Ihre Nachricht!
                    </div>
                </div>

                <div className="contact-elem4">
                    <h2 className="contact-elem4-header sl-h2">Verkauf & Handel</h2>
                    <div className="contact-elem4-line sl-Line sl-Line-norm"></div>
                    <div className="contact-elem4-text sl-Text">
                        Wir sind stetig auf der Suche nach regionalen, nachhaltigen und wertbewussten Partnern, die unsere Leidenschaft für hochqualitative Produkte aus Österreich teilen. <br />
                        Sie sind Getränkehändler, haben ein Restaurant oder führen einen Biofachgeschäft und möchten Ihr Sortiment um die Sonnleitner-Edelbrände und andere Produkte des Sonnleitner-Biobauernhofs erweitern? <br />
                        Wenden Sie sich bitte an <a href='mailto:verkauf@dersonnleinter.at?subject=Der Sonnleitner - Verkauf und Handel'>verkauf@dersonnleitner.at</a> <br />
                        Wir freuen uns auf Ihre Nachricht!
                    </div>
                </div>

            </div>
        )
    }
}
