import React, { Component } from 'react'

import './slideshow.css'

export default class Slideshow extends Component {
    constructor(props) {
        super(props);
        this.bigImg = this.props.imgs[0];
        this.bigImgRef = React.createRef();
    }

    /* CHANGEBIGIMG */
    /* func to change big img by clicking on small image */
    changeBigImg = (img) => {
        this.bigImg = img;
        this.bigImgRef.current.src = img
    }

    render() {
        return (
            <div className="slideshow">
                {/* BIG IMAGE */}
                <div className="slideshow-bigImg">
                    <img ref={this.bigImgRef} src={this.props.imgs[0]} alt="" />
                </div>
                
                {/* SMALL IMAGES */}
                <div className="slideshow-smallImgs">
                    <div className="slideshow-smallImgs-line sl-Line"></div>
                    {
                        this.props.imgs.map((img) => {
                            return <img src={img} className="slideshow-smallImgs-img" alt="" onClick={() => this.changeBigImg(img)} key={img}/>
                        })
                    }
                    <div className="slideshow-smallImgs-line sl-Line"></div>
                </div>

            </div>
        )
    }
}
