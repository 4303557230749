import React, { Component } from 'react'
import {NavLink} from 'react-router-dom'

import './footer.css'


import facebook from '../../img/Facebook.png'
import instagram from '../../img/Instagram.png'



export default class footer extends Component {
    

    render() {
        return (
            <footer className="footer">
                <div className="footer-box">
                    {/* SOCIAL MEDIA */}
                    <div className="footer-social">
                        <div className="footer-social-text">
                            FOLGEN SIE UNS!
                        </div>
                        <a href="https://www.facebook.com/Der-Sonnleitner-109103574281810/" target="_blank" rel="noopener noreferrer">
                            <img src={facebook} alt="gelbes facebook icon" />
                        </a>
                        <a href="https://www.instagram.com/der_sonnleitner/" target="_blank" rel="noopener noreferrer">
                            <img src={instagram} alt="gelbes instagram icon" />
                        </a>
                    </div>
                    <div className="footer-info">
                        <div className="footer-info-copyright" > © FAM. SONNLEITNER 2021 </div>
                        <NavLink exact to="/datenschutz" onClick={() => this.props.scrollToLogo()}>DATENSCHUTZERKLÄRUNG</NavLink>
                        <NavLink exact to="/impressum" onClick={() => this.props.scrollToLogo()} >IMPRESSUM</NavLink>
                        <NavLink exact to="/agb" onClick={() => this.props.scrollToLogo()} >AGB</NavLink>
                    </div>
                </div>
            </footer>
        )
    }
}
