import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import {Helmet} from 'react-helmet'

/* CSS */
import './jugendschutz.css'

/* ICONS */
import facebook from '../../../../img/Facebook.png'
import instagram from '../../../../img/Instagram.png'

export class Jugendschutz extends Component {

    componentDidMount() {
        document.body.scroll = "no";
        document.body.style.overflow = "hidden";
    }

    render() {
        return (
            <div className="jugendschutz">
                <Helmet>
                    <title>Der Sonnleitner-Biobauernhof Jugendschutz</title>
                    <meta name="description" content="Hier finden sie Informationen zum Jugendschutz"/>
                </Helmet>
                <div className="jugendschutz-box">
                    <h2 className="jugendschutz-header sl-h2">Wie alt muss ich sein?</h2>
                    <div className="jugendschutz-textBox sl-Text">
                        <div className="jugendschutz-textBox-text">
                            Für den Kauf und Konsum unserer Edelbrände müssen Sie in Österreich <b>zumindest das 18. Lebensjahr vollendet</b> haben. Diese Regelung des Jugendschutzes gilt seit 2019 einheitlich für alle österreichischen Bundesländer. <br /><br /> Wir freuen uns, wenn Sie uns auf unseren sozialen Kanälen folgen, auf denen Sie laufend neue Infos zu unserem Bauernhof und weiteren Produkten finden!
                    </div>
                        <div className="jugendschutz-textBox-links">
                            <a href="https://www.facebook.com/Der-Sonnleitner-109103574281810/"><img src={facebook} alt="facebook icon" /></a>
                            <a href="https://www.instagram.com/der_sonnleitner/"><img src={instagram} alt="instagram icon" /></a>
                        </div>
                        <div className="jugendschutz-textBox-text">
                            Der Jugendschutz ist uns sehr wichtig. Weitere Informationen zum österreichischen Jugendschutz finden Sie <a href="https://www.jugendportal.at/jugendschutz/alkohol">hier</a>
                        </div>
                        <NavLink className="jugendschutz-textBox-btn" exact to={"/"}>Zurück</NavLink>
                    </div>
                </div>
            </div>
        )
    }
}

export default Jugendschutz
