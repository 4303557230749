import React from 'react';
import {BrowserRouter} from 'react-router-dom'

import Header from './components/header/Header'
import Main from './components/main/Main'
import Footer from './components/footer/Footer'

import './fonts/fonts.css'
import './App.css'

function App() {
  

  /* SCROLL TO LOGO */
  /* When a link was clicked the window should scroll to logo */
  let scrollToLogo = () => {
    let main = document.getElementById("mainSection");
    setTimeout(() => { 
      window.scroll(0, findPos(document.getElementById("mainSection")) - 50);
      main.style.height = "100%"; 
    }, 100)
    
  }

  /* FINDPOS */
  /* Helper func for SCROLLTOLOGO to find an Element and handle offsett */
  let findPos = (obj) => {
    var curtop = 0;
    if (obj.offsetParent) {
      do {
        curtop += obj.offsetTop;
      } while (obj === obj.offsetParent);
      return [curtop];
    }
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Header scrollToLogo={scrollToLogo} />
        <Main scrollToLogo={scrollToLogo} />
        <Footer scrollToLogo={scrollToLogo} />
      </BrowserRouter>
    </div>
  );
}

export default App;
