import React, { Component } from 'react'
import { Route , NavLink } from "react-router-dom";

import MobileCta from './mobileCta/MobileCta';

/* CSS */
import './headerMobile.css'

/* IMGS */
import startImg from '../../../img/headerMobile/Startseite+Kontakt.jpg' // normal version
//import startImg from "../../../img/headerMobile/winterMobile.png";             // winter version
import urspImg from '../../../img/headerMobile/Ursprung.jpg'
import bestImg from '../../../img/headerMobile/IhreBestellung+UnsereProdukte.jpg'
import imprImg from '../../../img/headerMobile/Impressum+AGB.jpg'
import restImg from '../../../img/headerMobile/AlleAnderen.jpg'

import weihnachtenImg from '../../../img/weihnachten/weihnachten23mobile.jpg'

export class HeaderMobile extends Component {
    render() {
        return (
            <div className="headerMobile">
                <Route exact path={"/"}>
                    <img
                        src={startImg} //startimg
                        alt="Ludwig Sonnleitner im Gras mit einer Kiste Birnen"
                    />
                    <MobileCta scrollToLogo={this.props.scrollToLogo} />
                </Route>
                <Route exact path={"/ursprung"}>
                    <img
                        src={startImg} // urspImg
                        alt="Ludwig Sonnleitner sammelt Birnen von Boden in Körbe"
                    />
                    <MobileCta scrollToLogo={this.props.scrollToLogo} />
                </Route>
                <Route path={"/sortiment"}>
                    <img
                        src={startImg} // bestimg
                        alt="6 Flaschen Edelbrände mit Sonnleitner Logo"
                    />
                    <MobileCta scrollToLogo={this.props.scrollToLogo} />
                </Route>
                <Route exact path={"/bestellen"}>
                    <img
                        src={startImg} // bestimg
                        alt="6 Flaschen Edelbrände mit Sonnleitner Logo"
                    />
                    <MobileCta scrollToLogo={this.props.scrollToLogo} />
                </Route>
                <Route exact path={"/kontakt"}>
                    <img
                        src={startImg} // startimg
                        alt="Ludwig Sonnleitner im Gras mit einer Kiste Birnen"
                    />
                    <MobileCta scrollToLogo={this.props.scrollToLogo} />
                </Route>
                <Route exact path={"/datenschutz"}>
                    <img
                        src={startImg} // restimg
                        alt="Grüne und gelbe Birnen Nahaufnahme"
                    />
                    <MobileCta scrollToLogo={this.props.scrollToLogo} />
                </Route>
                <Route exact path={"/impressum"}>
                    <img
                        src={startImg} // imprimg
                        alt="Eine Kiste voll mit Birnen"
                    />
                    <MobileCta scrollToLogo={this.props.scrollToLogo} />
                </Route>
                <Route exact path={"/agb"}>
                    <img
                        src={startImg} // imprimg
                        alt="Eine Kiste voll mit Birnen"
                    />
                    <MobileCta scrollToLogo={this.props.scrollToLogo} />
                </Route>
                <Route exact path={"/success"}>
                    <img
                        src={startImg} // restimg
                        alt="Grüne und gelbe Birnen Nahaufnahme"
                    />
                    <MobileCta scrollToLogo={this.props.scrollToLogo} />
                </Route>
            </div>
        );
    }
}

export default HeaderMobile
