import React, { Component } from 'react'

/* CSS */
import './cartProduct.css'

/* ICONS */
import addImg from '../../../../../../img/plus.svg'
import subImg from '../../../../../../img/minus.svg'
import removeImg from '../../../../../../img/close.svg'

export class CartProduct extends Component {
    render() {
        return (
            <div className="cartProduct">
                <div className="cartProduct-name">{this.props.product.name}</div>
                <div className="cartProduct-size">{this.props.product.version.size}</div>
                <div className="cartProduct-price">{this.props.product.version.price}</div>
                <div className="cartProduct-amount">
                    <div className="cartProduct-amount-btn" onClick={() => this.props.addOne(this.props.product)}>
                        <img src={addImg} alt="plus" />
                    </div>
                    <div className="cartProduct-amount-amount">
                        {this.props.amount}
                    </div>
                    <div className="cartProduct-amount-btn" onClick={() => this.props.subOne(this.props.product)}>
                        <img src={subImg} alt="minus" />
                    </div>
                </div>
                <div className="cartProduct-remove" onClick={() => this.props.removeProd(this.props.product)}>
                    <img src={removeImg} alt="close" />
                </div>
            </div>
        )
    }
}

export default CartProduct
