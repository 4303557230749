import React, { Component } from 'react'
import {NavLink} from 'react-router-dom'

import './product.css'

export class Product extends Component {

    getLabel = () => {
        if(this.props.product.label !== undefined){
            return (
                <div className="product-label">
                    {this.props.product.label}
                </div>
            )
        }
    }

    render() {
        return (
            <div className="product">
                <NavLink to={"/sortiment/" +  this.props.product.url} onClick={() => this.props.scrollToLogo()}>
                    <img src={this.props.product.img[0]} alt="" />
                    {
                        this.getLabel()
                    }
                    <div className="product-name">{this.props.product.name}</div>
                </NavLink>
                
            </div>
        )
    }
}

export default Product
