import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

import "./startseite.css";

import marktfestImg0 from "../../../../../src/img/MarktfestSonntagberg/IP1_240903_Ludwig Sonnleitner + Thomas Raidl Bürgermeister Sonntagberg.jpg";
import marktfestImg1 from "../../../../../src/img/MarktfestSonntagberg/IP2_240903_Ludwig Sonlleitner + Obfrau des Bauernbundes Ortsgruppe Sonntagberg Erika Besendorfer.jpg";
import marktfestImg2 from "../../../../../src/img/MarktfestSonntagberg/IP3_240903_Ludwig Sonnleitner + Katharina I, ostköniginstellvertreterin.jpg";
import marktfestImg3 from "../../../../../src/img/MarktfestSonntagberg/IP4_240903Ludwig Sonnleitner + Katharina I, ostköniginstellvertreterin.jpg";
import marktfestImg4 from "../../../../../src/img/MarktfestSonntagberg/IP5_240903_Ludwig Sonnleitner + Obfrau des Bauernbundes Ortsgruppe Sonntagberg Erika Besendorfer.jpg";
import marktfestImg5 from "../../../../../src/img/MarktfestSonntagberg/IP6_240903_Ludwig Sonnleitner +Obfrau des Bauernbundes Ortsgruppe Sonntagberg Erika Besendorfer.jpg";
import sliderArrow from "../../../../../src/img/arrowHeaderYellow.svg";

const marktfestImgs = [
    marktfestImg0,
    marktfestImg1,
    marktfestImg2,
    marktfestImg3,
    marktfestImg4,
    marktfestImg5,
];

let marktfestIndex = 0;

class componentName extends Component {
    constructor() {
        super();
        this.marktfestIndex = 0;
        this.marktfestImgRef = React.createRef();
        this.marktfestSliderPrevRef = React.createRef();
        this.marktfestSliderNextRef = React.createRef();
    }

    componentDidMount() {}

    switchMarktfestImg(index) {
        let clippedIndex =
            index < 0
                ? marktfestImgs.length + index
                : index % marktfestImgs.length;

        this.marktfestIndex = clippedIndex;
        let nextImg = marktfestImgs[clippedIndex];
        this.marktfestImgRef.current.src = nextImg;
    }

    render() {
        return (
            <div className="startseite sl-Box">
                <Helmet>
                    <title>Der Sonnleitner-Biobauernhof</title>
                    <meta
                        name="description"
                        content="Biologische Edelbrände vom Sonnleitner Biobauernhof aus dem Herzen des Mostviertels. Einfach online bestellen, abholen oder nachhause schicken lassen!"
                    />
                </Helmet>

                {/* DESCRIP */}
                {/* <div className="startseite-descr">
                    <h1>Der Sonnleitner Biobauernhof</h1>
                    <div className="sl-Line sl-Line-norm"></div>
                    <p className="sl-Text">
                        Biologische <b>Edelbrände</b> vom{" "}
                        <b>Sonnleitner Biobauernhof</b> aus dem Herzen des
                        Mostviertels. Einfach <b>online bestellen</b>, abholen
                        oder nachhause schicken lassen!
                    </p>
                </div> */}

                {/* MARKTFEST SONNTAGBERG */}
                <div className="startseite-descr">
                    <h1>Der Sonnleitner Biobauernhof</h1>
                    <div className="sl-Line sl-Line-norm"></div>
                    <p className="sl-Text">
                        Wir sind stolz, auch im September 2024 am Marktfest
                        Sonntagberg bei der Schnapsprämierung Ortsprämierung und
                        Eisenstrassen Olympiade wieder mit unseren Edelbränden
                        überzeugen zu können. Wir bedanken uns für die
                        Prämierung und freuen uns, Ihnen weiterhin
                        ausgezeichnete Qualität anbieten zu können!
                    </p>
                </div>

                <div className="startseite-elem1">
                    <div
                        className="marktslider_btn  marktslider_prev"
                        ref={this.marktfestSliderPrevRef}
                    >
                        <img
                            src={sliderArrow}
                            alt="arrow icon"
                            onClick={() =>
                                this.switchMarktfestImg(this.marktfestIndex - 1)
                            }
                        />
                    </div>
                    <img
                        className="startseite-marktfest-img"
                        id="startseite-marktfest-slider"
                        src={marktfestImg0}
                        ref={this.marktfestImgRef}
                        alt="Der Sonnleitner Edelbrände, 3 Flaschen, Gin, Bergheu, Speckbirne"
                    ></img>{" "}
                    <div
                        ref={this.marktfestSliderNextRef}
                        className="marktslider_btn"
                    >
                        <img
                            src={sliderArrow}
                            alt="arrow icon"
                            onClick={() =>
                                this.switchMarktfestImg(this.marktfestIndex + 1)
                            }
                        />
                    </div>
                </div>

                {/* ELEMEN 1 */}
                {/* <div className="startseite-elem1">
                    <div
                        className="startseite-elem1-img"
                        title="Der Sonnleitner Edelbrände, 3 Flaschen, Gin, Bergheu, Speckbirne"
                    ></div> */}

                {/* WUSSTEN SIE? */}
                {/* <div className="startseite-elem1-dyk">
                        <div className="startseite-elem1-dyk-header">
                            Wussten Sie?
                        </div>
                        <div className="startseite-elem1-dyk-text">
                            <br />
                            Es gibt an die 300 Mostbirnensorten im Mostviertel{" "}
                            <br /> <br />
                            Ein Birnbaum kann bis zu 300 Jahre alt werden.
                            <br />
                            <br />
                            Ein Baum kann unter besten Bedingungen bis zu 1000kg
                            Birnen in einem Jahr bringen. <br />
                            <br />
                        </div>
                    </div>
                </div> */}

                <div className="startseite-elem2">
                    <div className="startseite-elem2-line sl-Line"></div>
                    <div className="startseite-elem2-text sl-Text">
                        Aus rein biologischem, <br /> zertifiziertem Anbau.
                    </div>
                    <div className="startseite-elem2-text sl-Text">
                        Gereift auf der Sonnenseite <br /> des Mostviertels.
                    </div>
                    <div className="startseite-elem2-text sl-Text">
                        Im Einklang mit Natur, <br /> Tradition und Region.
                    </div>
                    <div className="startseite-elem2-line sl-Line"></div>
                </div>
                <div className="startseite-elem3 sl-Text">
                    Überzeugen Sie sich selbst von der hohen Qualität unserer{" "}
                    <NavLink
                        to={"/sortiment"}
                        onClick={() => this.props.scrollToLogo()}
                    >
                        Produkte!
                    </NavLink>
                </div>
            </div>
        );
    }
}

export default componentName;
