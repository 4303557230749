import React, { Component } from 'react'
import {NavLink} from 'react-router-dom'
import {Helmet} from 'react-helmet'

/* CSS */
import './success.css'

/* ICONS */
import facebook from '../../../../img/Facebook.png'
import instagram from '../../../../img/Instagram.png'

export class Success extends Component {
    render() {
        return (
            <div className="success sl-Box">
                <Helmet>
                    <title>Der Sonnleitner-Biobauernhof SuccessPage</title>
                    <meta name="description" content="Die Order-SuccessPage von dersonnleitner.at" />
                </Helmet>
                <h2 className="success-header sl-h2">Vielen Dank f<span>ü</span>r Ihre Bestellung!</h2>
                <div className="success-text sl-Text">
                    Ihre Bestellung wurde erfolgreich übermittelt und wird umgehend berarbeitet. Wir haben Ihnen die Bestätigung Ihrer Bestellung per E-Mail geschickt. <br /><br />
                    Mit ihrem Einkauf habe Sie sich für ein regionales und nachhaltiges Unikat aus rein <NavLink to={"/ursprung"}>biologischem Anbau</NavLink> entschieden.
                    Dafür danken wir Ihnen und wünschen Ihnen damit eine genussreiche Zeit! <br /><br />
                    Einen sonnigen Gruß vom Sonntagberg<br/>
                    Fam. Sonnleitner <br/><br/>
                    Auf der Sonnenseite des Lebens < br/>
                    Seit 1910<br /><br />
                    Sie sind an einer <NavLink to={"/kontakt"}>Kooperation</NavLink> mit dem Sonnleitner-Biobauernhof interessiert? Wir freuen uns auf <a href='mailto:kontakt@dersonnleinter.at?subject=Der Sonnleitner - Marketing und Kooperationen'>Ihre Nachricht</a>. <br/>
                    Folgen Sie uns auf unseren sozialen Kanälen und bleiben Sie am Laufenden zu unserem Biobauernhof und neuen Produkten!
                </div>
                <div className="success-socialBox">
                    <a href="https://www.facebook.com/Der-Sonnleitner-109103574281810/" target="_blank" rel="noopener noreferrer">
                        <img src={facebook} alt="facebook icon yellow" />
                    </a>
                    <a href="https://www.instagram.com/der_sonnleitner/" target="_blank" rel="noopener noreferrer">
                        <img src={instagram} alt="instagram icon yellow" />
                    </a>
                </div>
                
            </div>
        )
    }
}

export default Success
