import React, { Component } from 'react'
import {NavLink, Route, Switch} from 'react-router-dom'
import {Helmet} from 'react-helmet'

/* CSS */
import './sortiment.css'

/* COMPONENTS */
import Product from './product/Product'
import ProductRoute from './productRoute/ProductRoute'


/* PDF (Preisliste) */
import preislistePdf from '../../../../img/PreislisteCLASSIC_202102.pdf'



export default class Sortiment extends Component {
    constructor(){
        super();
        this.cartBtnRef = React.createRef();
    }
    
    /* Show BestellBtn when user has a product in his cart */
    componentDidMount(){
        if(this.props.cart.length > 0){
            this.cartBtnRef.current.style.display = "block";
        }
    }

    /* Add active class to BestellBtn if user orders a Product & Btn is not already active */
    activateBestBtn = () => {
        if(this.cartBtnRef.current.style.display === ""){
            setTimeout(() => {
                this.cartBtnRef.current.classList.add("sortiment-cartBtn-active")
            }, 200)
            
        }
    }


    render() {
        
        return (
            <div className="sortiment sl-Box">
                <Helmet>
                    <title>Der Sonnleitner-Biobauernhof Sortiment</title>
                    <meta name="description" content="Auf der Suche nach einer Geschenkidee oder einem Mitbringsel? Finden Sie das ideale Bioprodukt aus unserer Doppel Genuss Region für jeden Anlass!" />
                </Helmet>
                {/* BESTELLUNG BTN */}
                <NavLink to={"/bestellen"} ref={this.cartBtnRef} className="sortiment-cartBtn" onClick={() => this.props.scrollToLogo()}>Zu Ihrer Bestellung</NavLink>

                {/* PRODUCT ROUTE */}
                <Switch>
                    {
                        this.props.products.map(product => (
                            <Route path={"/sortiment/" + product.url}>
                                <ProductRoute 
                                    product={product} 
                                    addFromSort={this.props.addFromSort}
                                    activateBestBtn={this.activateBestBtn}
                                    key={product.name}

                                />
                            </Route>
                        ))    
                    }
                </Switch>

                {/* PRODUCTS */}
                <div className="sortiment-box">
                    {
                        this.props.products.map(product => (
                            <Product 
                                product={product}
                                scrollToLogo={this.props.scrollToLogo}
                            />
                        ))
                    }
                </div>

                {/* PREISLISTE */}
                <div className="sortiment-Preisliste">
                    <h2 className="sortiment-Preisliste-header sl-h2">Unsere Preisliste</h2>
                    <div className="sl-Line sl-Line-norm"></div>
                    <a href={preislistePdf} download="DerSonnleinter Preisliste" className="sortiment-Preisliste-Btn sl-Text">Download</a>
                </div>
                
            </div>
        )
    }
}
