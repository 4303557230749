import React, { Component } from 'react'
import {NavLink} from 'react-router-dom'
import {Helmet} from 'react-helmet'

/* CSS */
import './bestellen.css'

/* ICONS */
import close from '../../../../img/close.svg'


/* COMPONENTS */
import OrderForm from './orderForm/OrderForm'

export default class Bestellen extends Component {
    constructor() {
        super();
        this.noItemAlertRef = React.createRef();
    }

    componentDidMount(){
        if(this.props.cart.length < 1){
            this.showNoItemAlert();
        }
        
    }

    showNoItemAlert = () => {
        this.noItemAlertRef.current.style.display = "flex";
    }

    hideNoItemAlert = () => {
        this.noItemAlertRef.current.style.display = "none";
    }

    render() {
        return (
            <div className="bestellen sl-Box">
                <Helmet>
                    <title>Der Sonnleitner-Biobauernhof Bestellen</title>
                    <meta name="description" content="Überzeugen Sie sich selbst von der hohen Qualität unserer Produkte und bestellen Sie jetzt einfach online! Abhof Verkauf, Abholung in Wien und Versand!"/>
                </Helmet>
                {/* NO ITEM ALERT */}
                <div ref={this.noItemAlertRef} className="noItemAlert" onClick={() => this.hideNoItemAlert()}>
                    <div className="noItemAlert-box" onClick={e => e.stopPropagation()}>
                        <div className="noItemAlert-header sl-h2">Sie möchten bei uns bestellen?</div>
                        <div className="noItemAlert-text sl-Text">
                            Derzeit haben Sie Ihrer Bestellung <b>noch keine Produkte</b> hinzugefügt. <br />
                            Sie können unsere Produkte direkt beim Schmöckern in unserem Sortiment auswählen. <br />
                            Wir freuen uns auf Ihre Bestellung! <br />
                        </div>
                        <NavLink className="sl-Text" to={"/sortiment"}>Unsere Produkte</NavLink>
                        <div className="noItemAlert-close" onClick={() => this.hideNoItemAlert()}>
                            <img src={close} alt="close icon, black cross"/>
                        </div>
                    </div>
                </div>

                <div className="bestellen-box">
                    {/* SHIPPMENT */}
                    <div className="bestellen-versand sl-Text">
                        <div className="bestellen-versand-header">
                            Unsere Versandkosten
                        </div>
                        <div className="bestellen-versand-text">
                            Die Höhe der Versandkosten ist abhängig von der Größe Ihrer Bestellung:
                        </div>
                        <div className="bestellen-versand-info">
                            <table>
                                <tbody>
                                    <tr>
                                        <th>Gesammtbestellwert Ihrer Bestellung</th>
                                        <th>Versandkosten</th>
                                    </tr>
                                    <tr>
                                        <td>Bis €20 (EURO)</td>
                                        <td>€6,00 (EURO)</td>
                                    </tr>
                                    <tr>
                                        <td>Von €21 (EURO) bis € 150 (EURO)</td>
                                        <td>€11,00 (EURO)</td>
                                    </tr>
                                    <tr>
                                        <td>Ab €151 (EURO)</td>
                                        <td>versandkostenfrei</td>
                                    </tr>
                                </tbody>
                            </table>
                            Wir bitten um Ihr Verständnis, dass ein Verkauf und Versand unserer Edelbrände nur an eine Adresse in Österreich möglich ist.
                        </div>

                        
                    
                    </div>

                    {/* ORDER */}
                    <div className="bestellen-order">
                        <OrderForm 
                            contactInfo={this.props.contactInfo}
                            cart={this.props.cart} 
                            addOne={this.props.addOne}
                            subOne={this.props.subOne}
                            removeProd={this.props.removeProd}
                            scrollToLogo={this.props.scrollToLogo}
                            changeContactInfo={this.props.changeContactInfo}
                            clearContactInfo={this.props.clearContactInfo}
                            clearCart={this.props.clearCart}
                        />
                    </div>
                </div>

                <div className="bestellen-tips-box">
                    <div className="bestellen-tips-tip1">
                        <div className="bestellen-tips">
                            <div className="bestellen-tips-header">
                                Wussten Sie?
                            </div>
                            <ul className="sl-Text">
                                <li>
                                    Sie können Ihre <b>Bestellung</b> auch bequem <b>in Wien abholen</b>!<br />
                                    Das geht ruckzuck! Sie wählen dazu im Bestellvorgang einfach <b>"Abholung"</b> und <b>"Abholung in Wien"</b> und hinterlassen
                                    Ihre Telefonnummer. Wir machen uns am darauffolgenden Werktag gleich einen Treffpunkt mit Ihnen aus.
                                </li>
                                <li>
                                    Selbst ist man <b>schneller als der Versand</b>!<br />
                                    Wenn Sie es besonders eilig haben, können Sie Ihre Bestellung entweder <b>direkt vom Hof oder in Wien</b> abholen
                                    und müssen nicht auf die Zustellung Ihres Packerls warten.
                                </li>
                                <li>
                                    Vorausdenker kaufen <b>versandkostenfrei</b>! <br />
                                    Wenn Sie auf Vorrat einkaufen oder gleich mehrere Freunde beschenken wollen, schicken wir Ihnen <b>Ihre Bestellung
                                    versandkostenfrei</b> zu! Das gilt ab einem Gesamtbestellwert von mehr als 151€ also z.B. bei einer Bestellung von drei
                                    Flaschen Gin und drei Flaschen Bärenfang (je 0.35L).
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
