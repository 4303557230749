import React, { Component } from 'react'
import {NavLink, Route, Switch} from 'react-router-dom'

/* CSS */
import './main.css'


/* ROUTE COMPONENTS */
import Startseite from './routes/Startseite/Startseite'
import StartseiteWeihnachten from './routes/Startseite/StartseiteWeihnachten.js'
import Ursprung from './routes/ursprung/Ursprung'
import Sortiment from './routes/sortiment/Sortiment'
import Bestellen from './routes/bestellen/Bestellen'
import Kontakt from './routes/kontakt/Kontakt'
import Datenschutz from './routes/datenschutz/Datenschutz'
import Impressum from './routes/impressum/Impressum'
import Agb from './routes/agb/agb.js'
import Jugendschutz from './routes/jugendschutz/Jugendschutz'
import Success from './routes/success/Success'

/* PRODUCT IMAGES */
import ginImg from '../../img/sortiment/Gin1.jpg'
import ginImg1 from '../../img/sortiment/Gin2.jpg'

import bergheuImg from '../../img/sortiment/Bergheu2.jpg'
import bergheuImg1 from '../../img/sortiment/Bergheu1.jpg'
import bergheuImg2 from '../../img/sortiment/Bergheu3.jpg'
import bergheuImg3 from '../../img/sortiment/Bergheu4.jpg'

import baerenImg from '../../img/sortiment/Baerenfang.jpg'
import baerenImg1 from '../../img/sortiment/Baerenfang1.jpg'
import baerenImg2 from '../../img/sortiment/Baerenfang2.jpg'

import roteBichlBirneImg from '../../img/sortiment/rote_bichlbirne.png'
import grueneBichlBirneImg from '../../img/sortiment/grüne_bichlbirne.png'

import speckbirneImg from '../../img/sortiment/Speckbirne1.jpg'
import speckbirneImg1 from '../../img/sortiment/Speckbirne.jpg'
import speckbirneImg2 from '../../img/sortiment/Speckbirne2.jpg'

import schmbirneImg from '../../img/sortiment/Schmotzbirne.jpg'

import apfelImg from '../../img/sortiment/Apfel.jpg'

import zwetschkeImg from '../../img/sortiment/Zwetschke.jpg'

import walnussImg from '../../img/sortiment/Walnuss.jpg'

import kochbirneImg from '../../img/sortiment/Kochbirne.png'

import setImg from '../../img/sortiment/GemuetlicherDreier1.jpg'
import setImg1 from '../../img/sortiment/GemuetlicherDreier2.jpg'

import set1Img from '../../img/sortiment/FlotterDreier1.jpg'
import set1Img1 from '../../img/sortiment/FlotterDreier2.jpg'


/* ICONS */
import closeIcon from '../../img/close.svg'


export default class Main extends Component {
    constructor(){
        super();
        this.state = {
            /* ShoppingCart */
            /* Contaions cartProducts. Object definition: {product, amount} */
            cart: [],

            /* ContactInfo */
            /* saves all contact Info to keep them during Route change */
            contactInfo: {
                name: null,
                email: null,
                text: null,
                tel: null,
                anschrift: null,
                plz: null,
                ort: null
            }
        }
        this.orderAlertBoxRef = React.createRef();
        this.initAlertRef = React.createRef();
    }




    /* PRODUCTS */
    products = [
        {
            name: 'Gin',
            url: 'Gin',
            info: "",
            img: [ginImg, ginImg1],
            versions: [
                {
                    size: '0.35L',
                    price: '27€'
                },
                {
                    size: '0.1L',
                    price: '10€'
                },

            ],
            text: "Fulminanter Wacholderduft und ein Bouquet an Kräutern gepaart mit Nelken, Kardamom und Zitrusfrucht – unser Gin ist so authentisch und natürlich, wie ihm Gin-Liebhaber selten begegnen.",
            label : "GEHEIMTIPP"
        },
        {
            name: 'Bergheu',
            url: 'Bergheu',
            info: "",
            img: [bergheuImg1, bergheuImg, bergheuImg2, bergheuImg3],
            versions: [
                {
                    size: '0.35L',
                    price: '27€'
                },
                {
                    size: '0.1L',
                    price: '10€'
                },

            ],
            text: "Unser Bergheu verströmt beim Öffnen augenblicklich die Fülle an Bergkräutern blühender Wiesen im höher gelegenen Mostviertel. Der Geschmack ist flüchtig süß, wilder Thymian und Schafgabe sorgen für einen zartbitteren Abgang."
        },
        {
            name: 'Bärenfang',
            url: 'Baerenfang',
            info: "",
            img: [baerenImg, baerenImg1, baerenImg2],
            versions: [
                {
                    size: '0.35L',
                    price: '27€'
                },
                {
                    size: '0.1L',
                    price: '10€'
                },

            ],
            text: "Unser Bärenfang ist ein Klassiker! Frischer Duft, kraftvolles, süßes Honigaroma - schmeichelt mild dem Gaumen und wärmt im Abgang. Vor dem Öffnen gut schütteln!",
            label: "DER BELIEBTESTE"
        },
        {
            name: 'Speckbirne',
            url: 'Speckbirne',
            info: "",
            img: [speckbirneImg1, speckbirneImg, speckbirneImg2],
            versions: [
                {
                    size: '0.35L',
                    price: '20€'
                },
                {
                    size: '0.1L',
                    price: '8€'
                },

            ],
            text: "Die Speckbirne gehört zu den beliebtesten Mostbirnen - bei uns wird sie am Südhang besonders süß und entwickelt bei wenig Säure und Gerbstoff ihre volle Reife. Der Edelbrand entwickelt dementsprechend von Karamell über Orange und Feige einen zuckersüßen Akkord."
        },
        {
            name: 'Kochbirne',
            url: 'Kochbirne',
            info: "",
            img: [kochbirneImg],
            versions: [
                {
                    size: '0.5L',
                    price: '13€'
                }
            ],
            text: " Die Kochbirne ist die erste! Sie gehört zu den ersten Birnen, die reif werden und zu den ersten die geerntet werden. Sie wird sehr schnell patzig, daher braucht es Erfahrung und Geschick, den Zeitpunkt der Ernte und die zeitnahe Verarbeitung richtig zu treffen. Unser Edelbrand verbreitet einen vollfruchtigen Duft. Bei anfänglicher frischer Schärfe schmeichelt der Edelbrand mild dem Gaumen und hinterlässt den Geschmack der vollen reifen Kochbirne und etwas Kräuterwürze. \n In unserer Doppel Genussregion ist unser über 150 Jahre alter Kochbirnen-Baum eine Rarität und der Edelbrand, den wir daraus herstellen wurde von der Doppel Genussregion mit Gold ausgezeichnet. ",
            label: "NEU IM SORTIMENT"
        },
        {
            name: 'Rote Bichlbirne',
            url: 'RoteBichlbirne',
            info: "",
            img: [roteBichlBirneImg],
            versions: [
                {
                    size: '0.5L',
                    price: '13€'
                }
            ],
            text: "Das Fleisch der Roten Bichlbirne (auch Kletzenbirne genannt) ist sehr saftig und schmeckt beim Hineinbeißen herb und süß. Sie ist ein im Mostviertel beliebtes Obst zum Dörren und eignet sich sehr gut für Kletzen. Der Edelbrand, der aus ihr gewonnen wird, erinnert daher im Geschmack auch an Dörrbirnen. Der Abgang ist erhebend mit einem Hauch von Zitronengras und Zeder.",
            label: "NEU IM SORTIMENT"
        },
        {
            name: 'Grüne Bichlbirne',
            url: 'GrüneBichlbirne',
            info: "",
            img: [grueneBichlBirneImg],
            versions: [
                {
                    size: '0.5L',
                    price: '13€'
                }
            ],
            text: "Die Grüne Bichlbirne hat in ihrem gelblichweißen, saftigen Fruchtfleisch zwei Seelen versteckt: die eine ist frühlingshaft und schmeckt nach Honig, die andere entfaltet sich im Abgang und ist herb und krampisch – denn sauer macht lustig!",
            label: "NEU IM SORTIMENT"
        },
        {
            name: 'Schmotzbirne',
            url: 'Schmotzbirne',
            info: "",
            img: [schmbirneImg],
            versions: [
                {
                    size: '0.5L',
                    price: '13€'
                }
            ],
            text: "Die Schmotzbirne ist die Kräftige, Unverwüstliche und besonders Fruchtbare – nach zeitgerechter Ernte und sorgsamer Verarbeitung, wird man beim Genuss des Edelbrands mit einem einzigartigen süßen Geschmack am Gaumen, einer Note von Dörrobst, Zitrus und Minze im Abgang belohnt."
        },
        {
            name: 'Apfel',
            url: 'Apfel',
            info: "",
            img: [apfelImg],
            versions: [
                {
                    size: '0.5L',
                    price: '13€'
                }
            ],
            text: "Die über Jahrhunderte im Mostviertel beheimateten Apfelsorten entfalten ein kräftiges, fruchtiges und raumfüllendes Bouquet. Unser Apfelcuvée schmeckt lieblich süß und ist nussig im Abgang."
        },
        {
            name: 'Zwetschke',
            url: 'Zwetschke',
            info: "",
            img: [zwetschkeImg],
            versions: [
                {
                    size: '0.5L',
                    price: '13€'
                }
            ],
            text: "Unsere Zwetschkenbäume zeigen mit dem hohen Zuckergehalt ihrer Früchte, wie wohl sie sich bei uns am Südhang fühlen. Dementsprechend schmeckt der Edelbrand süß und blumig. Typisch für Steinobstbrände ist der Marzipangeschmack im Abgang."
        },
        {
            name: 'Walnuss',
            url: 'Walnuss',
            info: "(Ansatzschnaps)",
            img: [walnussImg],
            versions: [
                {
                    size: '0.5L',
                    price: '13€'
                }
            ],
            text: "Angesetzt mit grünen Walnüssen steigt der Duft rund, mild und weich in die Nase. Unser Walnuss entfaltet seinen Geschmack herb und trocken. Zimt und Nelken treten im Abgang hervor. Vor dem Öffnen gut schütteln!"
        },
        {
            name: "Gemütlicher Dreier",
            url: "GemuetlicherDreier",
            info: "(Gin, Speckbirne und Bergheu)",
            img: [setImg, setImg1],
            versions: [
                {
                    size: '3x 0.1L',
                    price: '35€'
                }
            ],
            text: "Authentisch, zuckersüß und zartbitter – Gin, Bergheu und Speckbirne: ein Dreiklang im Einklang mit unserem Mostviertel. Das ideale Geschenk für Feinschmecker und Mostviertelfans!",
            label: "TOP MITBRINGSEL"
        },
        {
            name: "Flotter Dreier",
            url: "FlotterDreier",
            info: "(Bergheu, Bärenfang und Speckbirne)",
            img: [set1Img, set1Img1],
            versions: [
                {
                    size: '3x 0.1L',
                    price: '35€'
                }
            ],
            text: 'Süßes Honigaroma, zartbittere Bergkräuter und die zuckersüße Note von Karamell, Orange und Feige – hier findet sich ein Freudenfest für den Gaumen. Bärenfang, Bergheu und Speckbirne – das ideale Geschenk für gute Freunde und die, die es noch werden sollen!',
            label: "TOP MITBRINGSEL"

        },
    ]


    /*######### CONTACTINFO FUNCS ####### */

    /* CHANGE CONTACT INFO */
    /* Changes one Info */
    /* param: infoName -> Name of infovar which gets changed */
    /*        newInfo  -> info which gets inserted */
    changeContactInfo = (infoName, newInfo) => {
        let newContactInfo = this.state.contactInfo;    /* create copy of contactInfo */
        newContactInfo[infoName] = newInfo;             /* replace Info with newInfo */

        this.setState({
            contactInfo: newContactInfo
        });
    }

    /* CLEAR CONTACT INFO */
    /* sets all contactinfo vars to null */
    clearContactInfo = () => {
        let clearContactInfo = {
            name: null,
            email: null,
            text: null,
            tel: null,
            anschrift: null,
            plz: null,
            ort: null
        }
        this.setState({
            contactInfo: clearContactInfo
        })
    }

    /* ############## CART FUNCS ################# */

    /* CLEAR CART */
    /* Sets cart to empty array */
    clearCart = () => {
        this.setState({
            cart: []
        })
    }

    /* ADD TO CART */
    /* adds a Product & Amount to cart */
    addToCart = (cartProduct, amount) => {
        /* check if product with same version is allready in cart */
        let inCart = false;
        for (let i = 0; i < this.state.cart.length; i++) {
            /* if allready in cart add up amounts */
            if (this.state.cart[i].cartProduct.name === cartProduct.name && this.state.cart[i].cartProduct.version === cartProduct.version) {
                let newCart = [...this.state.cart] /* copy old cart */
                newCart[i].amount += amount;
                this.setState({
                    cart: newCart
                })
                inCart = true;
            }
        }
        if (!inCart) this.state.cart.push({
            cartProduct,
            amount
        })
    }

    /* ADD FROM SORT */
    /* This func is called when adding Product in Sortiment */
    /* to add Product and give an Order Alert */
    addFromSort = (cartProduct, amount) => {
        this.addToCart(cartProduct, amount);
        this.orderAlert();
    }

    /* ADD ONE */
    /* This func is called when adding One to the amount of a Product in Bestellen */
    addOne = (cartProduct) => {
        this.addToCart(cartProduct, 1);
    }

    /* SUB ONE */
    /* This func is called when subtracting One from the amount of a Product in Bestellen */
    subOne = (cartProduct) => {
        /* search for product in cart */
        for(let i = 0; i < this.state.cart.length; i++){
            if (this.state.cart[i].cartProduct.name === cartProduct.name && this.state.cart[i].cartProduct.version === cartProduct.version) {
                let newCart = [...this.state.cart]  //copy old cart
                newCart[i].amount -= 1;             //modify new cart
                if (newCart[i].amount < 1) {          //if less than 1 of prod
                    newCart.splice(i, 1);           //remove product
                }
                this.setState({ cart: newCart })      //replace old cart with new cart
            }
        }
    }

    /* REMOVE PRODUCT */
    /* Remove a Prouct from the cart */
    removeProd = (cartProduct) => {
        //search for product to remove
        for (let i = 0; i < this.state.cart.length; i++) {
            if (this.state.cart[i].cartProduct.name === cartProduct.name && this.state.cart[i].cartProduct.version === cartProduct.version) {
                let newCart = [...this.state.cart]  //copy old cart
                newCart.splice(i, 1);               //remove product
                this.setState({ cart: newCart })      //replace old cart with new cart
            }
        }
    }


    /* ORDER ALERT */
    /* Shows an alert that a Prouct was added to Cart */
    orderAlert = () => {
        let newAlert = document.createElement("div");
        newAlert.classList = "orderAlert";

        let newAlertHeader = document.createElement("div");
        newAlertHeader.classList = "orderAlert-header";
        newAlertHeader.innerHTML = "Das Produkt wurde Ihrer Bestellung hinzugefügt!"
        newAlert.appendChild(newAlertHeader);

        let newAlertInfo = document.createElement("div");
        newAlertInfo.classList = "orderAlert-info"
        newAlertInfo.innerHTML = "Ihre Bestellung finden Sie unter 'Ihre Bestellung'"
        newAlert.appendChild(newAlertInfo);

        /* close icon */
        let newAlertClose = document.createElement("div");
        newAlertClose.classList = "orderAlert-close"

        let closeImg = document.createElement("img");
        closeImg.src = closeIcon
        newAlertClose.appendChild(closeImg)

        newAlertClose.addEventListener("click", () => {
            newAlert.remove();
        })
        newAlert.appendChild(newAlertClose)

        this.orderAlertBoxRef.current.appendChild(newAlert)
        setTimeout(() => {
            newAlert.remove();
        }, 4000)
    }


    /* CLOSE INIT ALERT */
    /* func to close Initial Alert */
    closeInitAlert = () => {
        this.initAlertRef.current.classList.add("initAlert-inactive")
    }


    render() {
        return (
            <main id="mainSection" className="mainSection">
                <Switch>
                    <Route
                        exact
                        path={"/"}
                        render={() => (
                            <Startseite
                                scrollToLogo={this.props.scrollToLogo}
                            />
                        )}
                    ></Route>
                    <Route
                        exact
                        path={"/ursprung"}
                        render={() => (
                            <Ursprung scrollToLogo={this.props.scrollToLogo} />
                        )}
                    ></Route>
                    <Route
                        path={"/sortiment"}
                        render={() => (
                            <Sortiment /* Sortiment has no exact path to load route & subroutes */
                                addFromSort={this.addFromSort}
                                products={this.products}
                                scrollToLogo={this.props.scrollToLogo}
                                cart={this.state.cart}
                            />
                        )}
                    ></Route>
                    <Route
                        path={"/bestellen"}
                        render={() => (
                            <Bestellen
                                contactInfo={this.state.contactInfo}
                                changeContactInfo={this.changeContactInfo}
                                clearContactInfo={this.clearContactInfo}
                                cart={this.state.cart}
                                clearCart={this.clearCart}
                                addOne={this.addOne}
                                subOne={this.subOne}
                                removeProd={this.removeProd}
                                scrollToLogo={this.props.scrollToLogo}
                            />
                        )}
                    ></Route>
                    <Route
                        path={"/kontakt"}
                        render={() => (
                            <Kontakt scrollToLogo={this.scrollToLogo} />
                        )}
                    ></Route>
                    <Route
                        path={"/datenschutz"}
                        component={Datenschutz}
                    ></Route>
                    <Route
                        exact
                        path={"/impressum"}
                        component={Impressum}
                    ></Route>
                    <Route exact path={"/agb"} component={Agb}></Route>
                    <Route
                        exact
                        path={"/jugendschutz"}
                        component={Jugendschutz}
                    ></Route>
                    <Route
                        exact
                        path={"/success"}
                        render={() => <Success />}
                    ></Route>
                </Switch>

                {/* INITAL ALERT (ALTER & COOKIES) */}
                <div ref={this.initAlertRef} className="initAlert">
                    <div className="initAlert-box">
                        <div className="initAlert-box-header sl-h2">
                            Willkommen auf der Sonnenseite!
                        </div>
                        <div className="initAlert-box-text sl-Text">
                            Mit dem Besuch unserer Website bestätigen Sie, das
                            gesetzliche Mindestalter für Alkoholkonsum erreicht
                            zu haben. Außerdem stimmen Sie der Verwendung von
                            Cookies zu. Weitere Informationen zu unserer
                            Verwendung von Cookies finden Sie unter{" "}
                            <NavLink
                                exact
                                to="datenschutz"
                                onClick={this.closeInitAlert}
                            >
                                Datenschutzerklärung
                            </NavLink>
                        </div>
                        <div
                            className="initAlert-box-Btn sl-Text"
                            onClick={this.closeInitAlert}
                        >
                            Weiter zur Seite
                        </div>
                        <NavLink to="/jugendschutz">
                            Wie alt muss ich sein?
                        </NavLink>
                    </div>
                </div>

                {/* ORDER ALERT */}
                <div
                    ref={this.orderAlertBoxRef}
                    className="orderAlertBox"
                ></div>
            </main>
        );
    }
}
