import React, { Component } from 'react'
import {Helmet} from 'react-helmet'

/* CSS */
import './ursprung.css'

/* COMPONENTS */
import Slideshow from './slideshow/Slideshow'


/* IMAGES */
import urspElem1Img1 from '../../../../img/ursprung/Ursprung28k.jpg'
import urspElem1Img2 from '../../../../img/ursprung/Ursprung29K.jpg'
import urspElem1Img3 from '../../../../img/ursprung/Ursprung30K.jpg'
import urspElem1Img4 from '../../../../img/ursprung/Ursprung31K.jpg'

import urspElem2Img1 from '../../../../img/ursprung/Ursprung6K.jpg'
import urspElem2Img2 from '../../../../img/ursprung/Ursprung4K.jpg'
import urspElem2Img3 from '../../../../img/ursprung/Ursprung9K.jpg'

import urspElem3Img1 from '../../../../img/ursprung/Ursprung17K.jpg'
import urspElem3Img2 from '../../../../img/ursprung/Ursprung10K.jpg'
import urspElem3Img3 from '../../../../img/ursprung/Ursprung11K.jpg'

import urspElem4Img1 from '../../../../img/ursprung/Ursprung7K.jpg'
import urspElem4Img2 from '../../../../img/ursprung/Ursprung22K.jpg'
import urspElem4Img3 from '../../../../img/ursprung/Ursprung23K.jpg'

import urspElem4Img5 from '../../../../img/ursprung/Ursprung14K.jpg'
import urspElem4Img4 from '../../../../img/ursprung/Ursprung28aK.jpg'
import urspElem4Img7 from '../../../../img/ursprung/Ursprung16K.jpg'



export default class Ursprung extends Component {
    constructor() {
        super();
        this.scrollerRef = React.createRef();
        this.activeScroller = false;
    }

    /* ARRAYS: ELEMENTS IMAGES */
    elem1Imgs = [urspElem1Img1, urspElem1Img2, urspElem1Img3, urspElem1Img4]
    elem2Imgs = [urspElem2Img1, urspElem2Img2, urspElem2Img3]

    elem3Imgs = [urspElem3Img2, urspElem3Img3, urspElem3Img1]

    elem4Imgs1 = [urspElem4Img1, urspElem4Img2, urspElem4Img3]
    elem4Imgs2 = [urspElem4Img4, urspElem4Img7, urspElem4Img5]

    componentDidMount() {
        window.addEventListener('scroll', this.showscroller);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.showscroller)
    }
    
    showscroller = () => {
        if(!this.activeScroller){
            if (document.documentElement.scrollTop > 1000) {
                this.scrollerRef.current.classList.remove("scroller-invis")
                this.scrollerRef.current.classList.add("scroller-active");
                this.activeScroller = true;
            }
        } else {
            if(document.documentElement.scrollTop < 1000) {
                this.scrollerRef.current.classList.add("scroller-invis")
                this.scrollerRef.current.classList.remove("scroller-active");
                this.activeScroller = false;
            }
        }
        
    }

    hideScroller = () => {
        this.scrollerRef.current.classList.remove("scroller-active");
    }


    render() {
        return (
            <div className="ursprung sl-Box" onScroll={e => this.showscroller(e)}>
                <Helmet>
                    <title>Der Sonnleitner-Biobauernhof Ursprung</title>
                    <meta name="description" content="Im Einklang mit Tradition, Region und Natur – erfahren Sie mehr zu unserer Biolandwirtschaft am Sonnleitner-Biobauernhof Großleiten am Sonntagberg!" />
                </Helmet>
                <div ref={this.scrollerRef} className="ursprung-scroller" onClick={() => {this.props.scrollToLogo(); this.hideScroller()}}></div>

                {/* ELEMENT 1 */}
                <div className="ursprung-elem1">
                    <div className="ursprung-elem1-slideshow">
                        <Slideshow imgs={this.elem1Imgs} />
                    </div>
                    <div className="ursprung-elem1-textBox">
                        <h2 className="ursprung-header sl-h2">Gut Ding braucht Weile,<br /> heißt es</h2>
                        <div className="sl-Line sl-Line-norm"></div>
                        <div className="ursprung-elem1-textBox-text sl-Text">
                            Wir haben keine Mindestmengen zu erfüllen und müssen nicht unter Zeitdruck viele Produkte herstellen. <br /><br />
                                Wir nehmen uns alle Zeit, die es braucht um ein <b>Produkt in bester Qualität</b> zu erzeugen – und das schmeckt man. Um zu verstehen, was den Geschmack der Sonnleitner-Spirituosen so einzigartig macht, sollte man etwas zur <b>Geschichte unserer Edelbrände</b> kennen und zur Region, aus der sie kommen. <br /> <br />
                                <b>Drei Faktoren</b> sind dabei entscheidend: <br />
                            <ul>
                                <li>die <b>Lage</b> ist eine natürlich gewachsene Voraussetzung, die uns die Natur bietet</li>
                                <li>unsere nachhaltige <b>Bio-Bewirtschaftung</b></li>
                                <li>das <b>traditionelle Handwerk</b> des Brennens, wie es in unserer Familie seit Generationen praktiziert und verfeinert wird.</li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* ELEMENT 2 */}
                <div className="ursprung-elem2">
                    <div className="ursprung-elem2-textBox">
                        <h2 className="ursprung-header sl-h2">Die Lage unseres Hofes</h2>
                        <div className="ursprung-elem2-textBox-line sl-Line sl-Line-norm"></div>
                        <div className="ursprung-elem2-textBox-text sl-Text">
                            Die <b>sonnenreiche Lage</b> unseres Hofes und die historische Entwicklung unserer Region bilden den Grundstock für die Tradition des Schnapsbrennens in unserer Familie. <br /> <br />
                        </div>
                    </div>
                    <div className="ursprung-elem2-box2">
                        <div className="ursprung-elem2-img">
                            <Slideshow imgs={this.elem2Imgs} />
                        </div>
                        <div className="ursprung-elem2-textBox2 sl-Text">
                            Unser Hof Großleiten liegt am Südhang des <b>Sonntagberger Rückens</b> zwischen der Basilika Sonntagberg und St. Leonhard am Walde. Dieser Hang hat den ganzen Tag <b>viel Sonne</b>, was eine <b>wichtige Voraussetzung</b> dafür ist, dass unser Obst gut gedeihen kann und süß schmeckt. Das war aber nicht immer so. Bis ins 13. Jahrhundert war der Sonntagberger Rücken Urwald. Erst in dieser Zeit wurde über die Jahre hinweg diese schwer zugängliche Gegend teilweise gerodet und besiedelt. Die Bauernhöfe stehen im Mostviertel weiter verstreut.
                            Bis heute ist diese Gegend daher <b>sehr verkehrsarm</b> und die Höfe liegen von Feldern, Wiesen und Wäldern umgeben, geschmiegt an den Bergrücken. Die einzelnen Grundstücke sind durch Birnbaumzeilen und diverse Apfelsorten voneinander abgegrenzt. <br />
                        </div>
                    </div>
                </div>

                {/* ELEMENT 3 */}
                <div className="ursprung-elem3">
                    <div className="ursprung-elem3-textBox">
                        <h2 className="ursprung-header sl-h2">
                            Zusammenhänge verstehen: <br />
                                unsere extensiven Streuobstbestände
                           </h2>
                        <div className="ursprung-elem3-textBox-line sl-Line sl-Line-norm"></div>
                        <div className="ursprung-elem3-textBox-text sl-Text">
                            Eine <b>verständnisvolle und biologische Bewirtschaftung</b> unseres Landes im absoluten Einklang mit der Natur ist der Nährboden unserer traditionellen Destillation. <br /> <br />
                                Wir betreiben eine reine Bio-Rinderviehzucht. Die Tiere sind dabei einen Großteil des Jahres im Freien. <br />
                                Das Obst, das wir zu Schnaps brennen, kommt von unseren sogenannten Streuobstbäumen. Das sind Obstbäume mit einer Stammhöhe von mindestens 1 bis 1,8 Meter, die nur im <b>Zusammenspiel mit unseren Wiederkäuern</b> so optimal gedeihen können.
                                 Denn die Bäume benötigen Freiräume rundum, um ihre Blüte und Fruchtreife bestens entfalten zu können. Die Tiere wiederum ernähren sich vom Fruchtfall, halten durch das Weiden die Flächen sauber und frei und geben an die Bäume wertvolle Nährstoffe zurück.
                           </div>
                    </div>
                    <div className="ursprung-elem3-slideshow">
                        <Slideshow imgs={this.elem3Imgs} />
                    </div>
                </div>

                {/* ELEMENT 4 */}
                <div className="ursprung-elem4">
                    <h2 className="ursprung-header sl-h2">Unser Handwerk & unsere Tradition </h2>
                    <div className="ursprung-elem4-line sl-Line sl-Line-norm"></div>
                    <div className="ursprung-elem4-textBox1 sl-Text">
                        Die <b>handwerkliche Sorgfalt</b> und die konzentrierte Hingabe im Arbeitsprozess bereiten den Weg für die hohe Qualität unserer Produkte.
                        </div>
                    <div className="ursprung-elem4-box">
                        <div className="ursprung-elem4-textBox2 sl-Text">
                            Seit hier im <b>13. Jahrhundert</b> die ersten Bauernhöfe gebaut wurden, wird im Mostviertel auch Schnaps gebrannt. In dieser entlegenen Region war es für das Überleben notwendig, die Kraft der Früchte auf diese Art zu konservieren um u.a. Alkohol zur Reinigung und Medizin aus ihnen zu gewinnen.
                            Durch Kaiserin Maria Theresia und Kaiser Joseph II. wurde das <b>Anpflanzen von Streuobstbäumen</b> gefördert und vorangetrieben. Eine dadurch einsetzende  Verbesserung der Infrastruktur (wie z.B. den Bau der Eisenbahn) verhalf der Region zu einigem Wohlstand und so bekam sie im 19. Jahrhundert schließlich den Namen "Mostviertel". Die Idee, den Schnaps auch als Genussmittel zum Trinken zu verwenden, aus der die einzelne Frucht heraus geschmeckt werden kann, hat ihren Ursprung in dieser Zeit.
                            </div>
                        <div className="ursprung-elem4-box-img">
                            <Slideshow imgs={this.elem4Imgs1} />
                        </div>
                    </div>
                    <div className="ursprung-elem4-box2">
                        <div className="ursprung-elem4-box2-img">
                            <Slideshow imgs={this.elem4Imgs2} />
                        </div>
                        <div className="ursprung-elem4-textBox3 sl-Text">
                            Unsere Familie hat die <b>jahrhundertealte Tradition</b> des Schnapsbrennens über die Generationen perfektioniert. Wir arbeiten mit einem <b>einfachen Brenngerät</b> das ohne elektronische Steuerung nur mit überlegter Holzfeuerung, <b>viel Geschick</b> und <b>jahrelanger Erfahrung</b> bedient werden kann. Denn uns ist jeder einzelne Schritt in der Produktion so wichtig, dass wir ihn nicht einer Maschine oder unerfahrenen Händen anvertrauen möchten. Von der Ernte bis hin zu Ihrem sorgsam fertig verpackten Produkt - passiert bei uns daher alles händisch. Deshalb ist jeder bei uns gebrannte <b>Schnaps ein Unikat</b>. Wir leben diese Arbeitsweise aus Überzeugung und garantieren damit eine hohe Qualität unserer Produkte. Somit halten Sie am Ende ansprechende Destillation von bester Güte aus einer Hand in Ihren Händen.
                            </div>
                    </div>
                </div>
            </div>
        )
    }
}
