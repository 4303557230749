import React, { Component } from 'react'
import axios from 'axios'
import { NavLink, Redirect } from 'react-router-dom'

/* CSS */
import './orderForm.css'

/* COMPONENTS */
import './cartProduct/CartProduct'
import CartProduct from './cartProduct/CartProduct';

export class OrderForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: null,
            email: null,
            text: null,
            anschrift: null,
            plz: null,
            ort: null,
            tel: null,
            shipment: null,
            abholung: null,
            summe: null,
            redirectToSuccess: false,
        }

        /* this.messageSend = this.messageSend.bind(this)
        this.closeMessageSend = this.closeMessageSend.bind(this) */
        this.formNameRef = React.createRef();
        this.formEmailRef = React.createRef();
        this.cartRef = React.createRef();
        this.sumRef = React.createRef();
        this.formTextRef = React.createRef();
        this.shipmentRef = React.createRef();
        this.versandRef = React.createRef();
        this.abholungRef = React.createRef();
        this.abholungHeaderRef = React.createRef();
        this.abholungHofRef = React.createRef();
        this.abholungWienRef = React.createRef();
        this.abholungMapsRef = React.createRef();
        this.abholungTextRef = React.createRef();
        this.abholungTelRef = React.createRef();
        this.versandAnschRef = React.createRef();
        this.versandPlzRef = React.createRef();
        this.versandOrtRef = React.createRef();
        this.agbRef = React.createRef();
        this.submitRef = React.createRef();
    }


    /* ############ CART FUNCS ########### */


    /* NUMBER INPUT */
    /* for Inputs -> allows only numbers as input */
    numberInput = (e, maxLength) => {
        let str = e.target.value
        if (str.charAt(str.length - 1) < '0' || str.charAt(str.length - 1) > '9' || str.length > maxLength) {
            e.target.value = str.slice(0, -1)
        }
    }

    /* ORDER HEADER */
    /* Inserts Header depending if you have prods in your cart */
    orderHeader = () => {
        if (this.props.cart.length > 0) {
            return <div className="orderForm-form-cart-header">Ihre Bestellung</div>
        } else {
            return (
                <div className="orderForm-form-cart-empty">
                    Sie haben noch kein Produkt zu Ihrer Bestellung hinzugefügt. <br />
                     Unsere Produkte finden Sie unter <NavLink onClick={() => this.props.scrollToLogo()} exact to="sortiment">Unsere Produkte</NavLink>.
                </div>
            );
        }
    }

    /* ORDER FOOTER */
    /* Inserts a SUM footer for the Cart */
    orderFooter = () => {
        if (this.props.cart.length > 0) {
            let sum = 0;
            for (let i = 0; i < this.props.cart.length; i++) {
                let price = this.props.cart[i].cartProduct.version.price;
                price = price.slice(0, -1)
                price = parseInt(price, 10)
                sum += price * this.props.cart[i].amount;
            }
            //process shipment fees
            let shipping,
                shippingSum = 0,
                total,
                shippingText = "";
            if (this.state.shipment === 'Versand') {
                if (sum <= 20) {
                    shipping = <div className='orderForm-form-cart-footer-total'>Versandkosten: 6€</div>
                    shippingSum = 6;
                } else if (sum <= 150) {
                    shipping = <div className='orderForm-form-cart-footer-total'>Versandkosten: 11€</div>
                    shippingSum = 11;
                } else {
                    shipping = <div>Es werden keine Versandkosten erhoben.</div>;
                    shippingSum = 0;
                }
                if (shippingSum !== 0) {
                    total = <div > <b>Gesamtsumme: {sum + shippingSum}€</b></div>;

                } else {
                    shippingText = ", zuzüglich Versandkosten"
                }
            }


            return <div className="orderForm-form-cart-footer">
                Summe:
                    <span style={{ fontSize: "1rem" }} ref={this.sumRef}>{sum}</span>€ <br />
                {shipping}
                {total}
                <span>
                    Alle Preise inkl. 13% Mehrwertsteuer{shippingText}.
                    </span>
            </div>
        } else {
            return ""
        }
    }


    /* ########## CHANGE SHIPMENT ################### */

    /* CHANGE SHIPMENT TO ABHOLUNG */
    changeShipmentToAbhl = () => {
        let error = document.getElementById("orderForm-submitError")
        if (error != null) error.remove();
        this.setState({ shipment: 'Abholung' })
        this.abholungRef.current.classList.add("orderForm-form-selectShipment-btn-active");
        this.versandRef.current.classList.remove("orderForm-form-selectShipment-btn-active");
    }

    /* CHANGE SHIPMENT TO VERSAND */
    changeShipmentToVsnd = () => {
        let error = document.getElementById("orderForm-submitError")
        if (error != null) error.remove();
        this.setState({ shipment: 'Versand' })
        this.versandRef.current.classList.add("orderForm-form-selectShipment-btn-active");
        this.abholungRef.current.classList.remove("orderForm-form-selectShipment-btn-active");
    }


    /* SELECT SHIPMENT */
    /* renders orderform according to shipment version */
    selectShipment = () => {
        if (this.state.shipment === 'Abholung') {
            return (
                <div className="orderForm-selectAbholung">
                    <div ref={this.abholungHeaderRef} className="orderForm-selectAbholung-header">Wählen Sie Ihren Abholungsort</div>
                    <div className="orderForm-selectAbholung-btnBox">
                        <div ref={this.abholungHofRef} onClick={() => this.changeAbholungToHof()} className="orderForm-selectAbholung-btn">Abholung vom Hof</div>
                        <div ref={this.abholungWienRef} onClick={() => this.changeAbholungToWien()} className="orderForm-selectAbholung-btn">Abholung in Wien</div>
                    </div>
                    <div  className="orderForm-selectAbholung-mapBox">
                        <iframe 
                            ref={this.abholungMapsRef}
                            className="orderForm-selectAbholung-map"
                            title="sonnleitnerMaps"
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5338.981704836322!2d14.818445!3d48.004226!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4772373603cb057d%3A0x69f8add1a394b897!2sDer%20Sonnleitner-Biobauernhof!5e0!3m2!1sde!2sat!4v1612155782955!5m2!1sde!2sat" 
                            width="600" 
                            height="450" 
                            frameborder={""} 
                            allowFullScreen="" 
                            aria-hidden="false" 
                            tabIndex={"0"}
                        ></iframe>
                    </div>
                    <div ref={this.abholungTextRef} className="orderForm-selectAbholung-text"></div>
                    <label htmlFor={this.abholungTelRef}>Ihre Telefonnummer</label>
                    <input defaultValue={this.props.contactInfo.tel} onChange={e => { this.numberInput(e, 12); this.props.changeContactInfo("tel", e.target.value) }} ref={this.abholungTelRef} className="orderForm-selectAbholung-tel" type="tel" placeholder="" />
                </div>
            )
        }
        if (this.state.shipment === 'Versand') {
            /* workaround to delete Text from select Abholung after changing shipment */
            if(this.abholungTextRef.current != null) this.abholungTextRef.current.innerHTML = "";
            return (
                <div className="orderForm-selectVersand ">
                    <div className="orderForm-selectVersand-header">Ihre Adresse</div>
                    <div className="orderForm-selectVersand-topInput">
                        <label htmlFor={this.versandAnschRef}>Anschrift</label>
                        <input ref={this.versandAnschRef} onChange={(e) => { this.props.changeContactInfo("anschrift", e.target.value) }} defaultValue={this.props.contactInfo.anschrift} type="text" placeholder="Ihre Anschrift" />
                    </div>
                    <div className="orderForm-selectVersand-bottomInput">
                        <div className="orderForm-selectVersand-plzInput">
                            <label htmlFor={this.versandPlzRef}>Postleitzahl</label>
                        A - <input ref={this.versandPlzRef} onChange={e => { this.numberInput(e, 4); this.props.changeContactInfo("plz", e.target.value) }} defaultValue={this.props.contactInfo.plz} type="text" placeholder="0000" />
                        </div>
                    </div>

                    <div className="orderForm-selectVersand-bottomInput">
                        <label htmlFor={this.versandOrtRef}>Ort</label>
                        <input ref={this.versandOrtRef} onChange={e => this.props.changeContactInfo("ort", e.target.value)} defaultValue={this.props.contactInfo.ort} type="text" placeholder="Ihr Ort" />
                    </div>

                    <div className="orderForm-selectVersand-text2">Sie bezahlen Ihre Bestellung bequem per Überweisung. Bitte geben Sie im Verwendungszweck die Bestellnummer an! Diese bekommen Sie umgehend nach Abschluss Ihrer Bestellung per E-Mail zugeschickt. Der Versand erfolgt umgehend nach Eingang Ihrer Zahlung an die von Ihnen angegebene Anschrift durch die österreichische Post.</div>
                </div>
            )
        }
    }


    /* CHANGE ABHOLUNG TO WIEN */
    changeAbholungToWien = () => {
        this.setState({ abholung: 'Wien' })
        this.abholungWienRef.current.classList.add("orderForm-selectAbholung-btn-active");
        this.abholungHofRef.current.classList.remove("orderForm-selectAbholung-btn-active");
        this.abholungMapsRef.current.style.display = "none";
        this.abholungTextRef.current.innerHTML = "Sie holen Ihre Bestellung in Wien ab. Zur Terminvereinbarung und zur Vereinbarung eines Treffpunktes werden Sie am Werktag nach der Bestellung telefonisch von uns kontaktiert und eine Übergabe wird vereinbart. Sie bezahlen Ihre Bestellung per Überweisung oder in Bar (bitte lassen Sie uns Ihre bevorzugte Zahlungsmethode bei unserem Telefonat wissen!)."

    }

    /*  CHANGE ABHOLUNG TO HOF */
    changeAbholungToHof = () => {
        this.setState({ abholung: 'Hof' })
        this.abholungHofRef.current.classList.add("orderForm-selectAbholung-btn-active");
        this.abholungWienRef.current.classList.remove("orderForm-selectAbholung-btn-active");
        this.abholungMapsRef.current.style.display = "block";
        this.abholungTextRef.current.innerHTML = "Sie holen Ihre Bestellung selber vom Hof ab. Zur Terminvereinbarung werden Sie am Werktag nach der Bestellung telefonisch von uns kontaktiert und eine Übergabe wird vereinbart. Sie bezahlen dann Ihre Bestellung per Überweisung oder in Bar (bitte lassen Sie uns Ihre bevorzugte Zahlungsmethode bei unserem Telefonat wissen!)."
    }


    /* SEND ORDER */
    sendOrder = () => {
        let error = false;
        let errorElem = document.createElement("p")
        let errorMsg = "Bitte füllen sie folgende Felder aus: "

        /* delete old error if existing */
        let errorOld = document.getElementById("orderForm-submitError")
        if (errorOld != null) errorOld.remove();

        //check cart
        if (this.props.cart.length < 1) {
            errorMsg = "Bitte fügen sie ein Produkt ihrer Bestellung hinzu."
            error = true;
        } else {
            //check name
            if (this.formNameRef.current.value === '') {
                this.formNameRef.current.classList.add("orderForm-form-emptyAlert")
                errorMsg += "Ihr Name ,"
                error = true;
            }

            //check email
            if (this.formEmailRef.current.value === '') {
                this.formEmailRef.current.classList.add("orderForm-form-emptyAlert")
                errorMsg += " Ihre Email ,"
                error = true;
            }

            //check shipment
            if (this.state.shipment == null) {
                this.shipmentRef.current.classList.add("orderForm-form-emptyAlert")
                errorMsg += "Ihre Bestellart ,"
                error = true;
            } else {

                //check abholung
                if (this.state.shipment === 'Abholung') {

                    //check abholungs ort
                    if (this.state.abholung == null) {
                        this.abholungHeaderRef.current.classList.add("orderForm-form-emptyAlert")
                        errorMsg += " Abholungsort ,"
                        error = true;
                    }

                    //check Telefonnummer
                    if (this.abholungTelRef.current.value === '') {
                        this.abholungTelRef.current.classList.add("orderForm-form-emptyAlert")
                        errorMsg += " Ihre Telefonnummer ,"
                        error = true;
                    }
                } else {
                    //check Versand

                    //check Anschrift
                    if (this.versandAnschRef.current.value === '') {
                        this.versandAnschRef.current.classList.add("orderForm-form-emptyAlert")
                        errorMsg += " Ihre Anschrift ,"
                        error = true;
                    }

                    //check plz
                    if (this.versandPlzRef.current.value === '') {
                        this.versandPlzRef.current.classList.add("orderForm-form-emptyAlert")
                        errorMsg += " Ihre Plz ,"
                        error = true;
                    }

                    //check ort
                    if (this.versandOrtRef.current.value === '') {
                        this.versandOrtRef.current.classList.add("orderForm-form-emptyAlert")
                        errorMsg += " Ihr Ort ,"
                        error = true;
                    }
                }
            }

            if (!this.agbRef.current.checked) {
                document.getElementsByClassName("orderForm-form-agb")[0].style.background = "rgba(255, 0, 0, 0.1)"
                errorMsg += " Bitte bestätigen sie unsere AGB,"
                error = true;
            }

        }


        if (error) {
            //print error
            errorElem.id = "orderForm-submitError"
            errorElem.classList.add("orderForm-form-submit-error")
            errorMsg = errorMsg.slice(0, -1) //delete last ","
            errorElem.innerHTML = errorMsg;
            this.submitRef.current.appendChild(errorElem)
        } else {
            //send Email
            let emailData = {
                reciver: "bestellung",
                name: this.formNameRef.current.value,
                email: this.formEmailRef.current.value,
                text: this.formTextRef.current.value,
                cart: this.props.cart,
                shipment: this.state.shipment,
                summe: this.sumRef.current.innerHTML,
            }

            if (this.state.shipment === 'Abholung') {
                emailData.abholung = this.state.abholung;
                emailData.telefon = this.abholungTelRef.current.value;
            } else {
                emailData.anschrift = this.versandAnschRef.current.value;
                emailData.plz = this.versandPlzRef.current.value;
                emailData.ort = this.versandOrtRef.current.value;
            }

            this.email(emailData)
            
            
        }
    }

    /* Email the Data */
    /* sets redirect if successful */
    email = async (data) => {
        await axios.post('https://www.dersonnleitner.at/mail.php', data, {
            headers: {
                'content-type': 'text/json'
            }
        }).then((resp) => {
            if(resp.data === 1){
                this.setState({
                    redirectToSuccess: true
                })
            } else {

                /* delete old error if existing */
                let errorOld = document.getElementById("orderForm-submitError")
                if (errorOld != null) errorOld.remove();

                let errorElem = document.createElement("p")
                errorElem.classList.add("orderForm-form-submit-error")
                errorElem.innerHTML = "Wir konnten leider keine Email an die von Ihnen angegebene Email-Adresse senden. Bitte überprüfen Sie diese und versuchen es erneut."
                this.submitRef.current.appendChild(errorElem)
            }
        })
    }

    /* Redirects to Success page */
    redirect = () => {
        if(this.state.redirectToSuccess){
            this.props.clearCart();
            this.props.clearContactInfo();
            this.props.scrollToLogo();
            return (
                <Redirect push to="/success"/>
            )
        } else {

        }
    }

    



    /* ################## RENDER ######################### */
    render() {
        return (
            <div className="orderForm">
                <div className="orderForm-box">
                    {/* HEADER */}
                    <div className="orderForm-headerBox">
                        <h2 className="orderForm-headerBox-header sl-h2">Übersicht Ihrer Bestellung</h2>
                        <div className="sl-Line sl-Line-norm"></div>
                        <div className="orderForm-headerBox-text sl-Text">Hier können Sie Ihre Bestellung überprüfen und abschließen.</div>
                    </div>
                    {/* ###### INPUT ###### */}
                    <form className="orderForm-form" action="">
                        {/* NAME */}
                        <div className="orderForm-form-inputBox orderForm-form-topInputBox">
                            <label htmlFor="formName">Ihr Name</label>
                            <input ref={this.formNameRef} className="orderForm-form-inputBox-input" defaultValue={this.props.contactInfo.name} onChange={(e) => { this.props.changeContactInfo("name", e.target.value) }} type="text" placeholder="Geben Sie hier Ihren Namen ein"/>
                        </div>
                        {/* EMAIL */}
                        <div className="orderForm-form-inputBox orderForm-form-topInputBox">
                            <label htmlFor="formEmail">Ihre Email-Adresse</label>
                            <input ref={this.formEmailRef} className="orderForm-form-inputBox-input" defaultValue={this.props.contactInfo.email} onChange={(e) => { this.props.changeContactInfo("email", e.target.value) }} type="text" placeholder="Geben Sie hier Ihre Email-Adresse ein"/>
                        </div>
                        {/* TEXT AREA */}
                        <div className="orderForm-form-inputBox orderForm-form-textInput">
                            <label htmlFor="formText">Ihre Nachricht</label>
                            <textarea ref={this.formTextRef} id="formText" type="text" placeholder="Ihre Nachricht..." />
                        </div>

                        {/* SHOPPING-CART */}
                        <div ref={this.cartRef}className="orderForm-form-cart">

                            {/* cart header, switches between products in cart and no products in cart */}
                            {
                                this.orderHeader()
                            }

                            {/* list of all products in cart */}
                            {
                                this.props.cart.map((orderItem) => (
                                    <CartProduct
                                        product={orderItem.cartProduct} 
                                        amount={orderItem.amount} 
                                        addOne={this.props.addOne} 
                                        subOne={this.props.subOne} 
                                        removeProd={this.props.removeProd} 
                                    />
                                ))
                            }
                            

                        </div>
                        {/* cart footer */}
                        {
                            this.orderFooter()
                        }

                        {/* SHIPMENT */}
                        <div ref={this.shipmentRef} className="orderForm-form-selectShipment">
                            <div className="orderForm-form-selectShipment-text sl-Text">Wie bekommen sie Ihre Bestellung?</div>
                            <div ref={this.abholungRef} onClick={() => this.changeShipmentToAbhl()} className="orderForm-form-selectShipment-btn">Abholung</div>
                            <div ref={this.versandRef} onClick={() => this.changeShipmentToVsnd()} className="orderForm-form-selectShipment-btn">Versand</div>
                        </div>

                        {
                            this.selectShipment()
                        }


                        {/* AGB */}
                        <div className="orderForm-form-agb">
                            <input ref={this.agbRef} type="checkbox"/>
                            <div className="orderForm-form-agb-text">
                                Ich habe die <NavLink exact to="/agb" onClick={() => this.props.scrollToLogo()}>AGB</NavLink> gelesen und akzeptiere diese.
                            </div>
                        </div>
                        
                        {/* SUBMIT */}
                        <div ref={this.submitRef} className="orderForm-form-submit">
                            <input id="orderForm-form-submit-btn" type="Button" value="Bestellen" onClick={() => this.sendOrder()} readOnly={true}/>
                            <p className="orderForm-form-submit-text">
                                Wir verarbeiten Ihre Daten zum Zwecke der Bearbeitung Ihrer Bestellung <br/>
                                Für weitere Informationen lesen Sie bitte unsere <NavLink exact to="/datenschutz" onClick={() => this.props.scrollToLogo()}>Datenschutzerklärung</NavLink>.
                            </p>
                        </div>
                    </form>

                    {
                        this.redirect()
                    }
                </div>
            </div>
        )
    }
}

export default OrderForm
