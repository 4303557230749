import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

import "./startseite.css";

class startseiteWeihnachten extends Component {
    render() {
        return (
            <div className="startseite sl-Box">
                <Helmet>
                    <title>Der Sonnleitner-Biobauernhof</title>
                    <meta
                        name="description"
                        content="Biologische Edelbrände vom Sonnleitner Biobauernhof aus dem Herzen des Mostviertels. Einfach online bestellen, abholen oder nachhause schicken lassen!"
                    />
                </Helmet>

                {/* DESCRIP */}
                <div className="startseite-descr sl-Box">
                    <h3>Weihnachtsaktion!</h3>
                    <div className="sl-Line sl-Line-norm"></div>
                    <p className="sl-Text">
                        Unsere Edelbrände bekommen Sie nun für den Aufpreis von
                        nur <b>5€</b> in einer dazu passenden und vollkommen
                        einzigartigen Weihnachsverpackung aus Vollholz (Eiche
                        bzw. Fichte/Altholz-Fichte) inkl. Dekoration.
                        <br />
                        <br />
                        Somit verleihen Sie ihrem Geschenk eine sehr persönliche
                        und im Stil moderne und elegante Handschrift.
                        <br />
                        <br />
                        Jedes Stück ist hier bei uns am Hof handgefertigt und
                        ein Unikat! <br /> Seien Sie schnell, denn die Aktion
                        läuft nur solange der Vorrat reicht!
                        <br />
                        <br />
                        <b>
                            Richten Sie Ihre Bestellung bitte telefonisch an
                            +436767822612 <br /> oder per Mail an
                            bestellung@dersonnleitner.at
                        </b>
                        <br />
                        <br />
                        Verkauf ab Hof oder bei {' '}
                        <a href="https://oberklammer.at/impressum/">
                            Elektro Oberklammer
                        </a>
                        <br />
                        Abholung bei uns am Bauernhof vor Ort oder nach
                        telefonischer Vereinbarung
                    </p>
                </div>

                {/* ELEMEN 1 */}
                <div className="startseite-elem1">
                    <div
                        className="startseite-elem1-img"
                        title="Der Sonnleitner Edelbrände, 3 Flaschen, Gin, Bergheu, Speckbirne"
                    ></div>

                    {/* WUSSTEN SIE? */}
                    <div className="startseite-elem1-dyk">
                        <div className="startseite-elem1-dyk-header">
                            Wussten Sie?
                        </div>
                        <div className="startseite-elem1-dyk-text">
                            <br />
                            Es gibt an die 300 Mostbirnensorten im Mostviertel{" "}
                            <br /> <br />
                            Ein Birnbaum kann bis zu 300 Jahre alt werden.
                            <br />
                            <br />
                            Ein Baum kann unter besten Bedingungen bis zu 1000kg
                            Birnen in einem Jahr bringen. <br />
                            <br />
                        </div>
                    </div>
                </div>

                <div className="startseite-elem2">
                    <div className="startseite-elem2-line sl-Line"></div>
                    <div className="startseite-elem2-text sl-Text">
                        Aus rein biologischem, <br /> zertifiziertem Anbau.
                    </div>
                    <div className="startseite-elem2-text sl-Text">
                        Gereift auf der Sonnenseite <br /> des Mostviertels.
                    </div>
                    <div className="startseite-elem2-text sl-Text">
                        Im Einklang mit Natur, <br /> Tradition und Region.
                    </div>
                    <div className="startseite-elem2-line sl-Line"></div>
                </div>
                <div className="startseite-elem3 sl-Text">
                    Überzeugen Sie sich selbst von der hohen Qualität unserer{" "}
                    <NavLink
                        to={"/sortiment"}
                        onClick={() => this.props.scrollToLogo()}
                    >
                        Produkte!
                    </NavLink>
                </div>
            </div>
        );
    }
}

export default startseiteWeihnachten;
